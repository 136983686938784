.carruselAuto {
    width: 100%;
    height: 20em;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.divPrincipalCarruselAuto {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 2000px;
    overflow: hidden;
}

.divTituloCarruselAuto {
    position: absolute;
    left: 10%;
    top: 12.5%;
}
.tituloCarruselAuto {
    color: white;
    text-align: left;
    font-size: 1.5em;
}
.divFechaMensaje {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    /* Agregamos una transición para el divDerecho */
}

.contenedorDivFechaMensaje {
    color: white;
    width: 80%;
}
.fechaCarruselAuto {
    text-align: left;
    font-size: 0.75em
}
.mensajeCarruselAuto {
    text-align: left;
    font-size: 1em
}
.divFechaMensaje.salida {
    width: 100%;
    transform: translateX(100%);
    /* Movemos el divDerecho hacia la derecha para salir */
}
.controladorCarruselAuto {
    position: absolute;
    bottom:5%;
    right: 5%;
    width: 6em;
    height: 2em;
}
.filaControl {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
}
.itemControl {
    width: 0.70em;
    height: 0.70em
}
.itemControl:hover {
    background-color: grey;
}

.itemControl.resaltado {
    background-color: #cce5ff;
}

@media (min-width: 475px) {
    .carruselAuto {
        width: 100%;
        height: 25em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .divPrincipalCarruselAuto {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 2000px;
        overflow: hidden;
    }
    .divTituloCarruselAuto {
        position: absolute;
        left: 10%;
        top: 12.5%;
    }
    .tituloCarruselAuto {
        color: white;
        text-align: left;
        font-size: 2em;
    }
    .divFechaMensaje {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: transform 0.5s ease-in-out;
        /* Agregamos una transición para el divDerecho */
    }
    .contenedorDivFechaMensaje {
        color: white;
        width: 80%;
    }
    .fechaCarruselAuto {
        text-align: left;
        font-size: 0.75em
    }
    .mensajeCarruselAuto {
        text-align: left;
        font-size: 1em
    }
    .divFechaMensaje.salida {
        width: 100%;
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .controladorCarruselAuto {
        position: absolute;
        bottom:5%;
        right: 5%;
        width: 6em;
        height: 2em;
    }
    .filaControl {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .itemControl {
        width: 0.70em;
        height: 0.70em
    }
    .itemControl:hover {
        background-color: grey;
    }
    .itemControl.resaltado {
        background-color: #cce5ff;
    }
}

@media (min-width: 640px) {
    .carruselAuto {
        width: 100%;
        height: 30em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .divPrincipalCarruselAuto {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 2000px;
        overflow: hidden;
    }
    .divTituloCarruselAuto {
        position: absolute;
        left: 10%;
        top: 12.5%;
    }
    .tituloCarruselAuto {
        color: white;
        text-align: left;
        font-size: 2.5em;
    }
    .divFechaMensaje {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: transform 0.5s ease-in-out;
        /* Agregamos una transición para el divDerecho */
    }
    .contenedorDivFechaMensaje {
        color: white;
        width: 80%;
    }
    .fechaCarruselAuto {
        text-align: left;
        font-size: 1em
    }
    .mensajeCarruselAuto {
        text-align: left;
        font-size: 1.5em
    }
    .divFechaMensaje.salida {
        width: 100%;
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .controladorCarruselAuto {
        position: absolute;
        bottom:5%;
        right: 5%;
        width: 6em;
        height: 2em;
    }
    .filaControl {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .itemControl {
        width: 0.70em;
        height: 0.70em
    }
    .itemControl:hover {
        background-color: grey;
    }
    .itemControl.resaltado {
        background-color: #cce5ff;
    }
}

@media (min-width: 768px) {
    .carruselAuto {
        width: 100%;
        height: 30em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .divPrincipalCarruselAuto {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 2000px;
        overflow: hidden;
    }
    .divTituloCarruselAuto {
        position: absolute;
        left: 10%;
        top: 12.5%;
    }
    .tituloCarruselAuto {
        color: white;
        text-align: left;
        font-size: 3em;
    }
    .divFechaMensaje {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: transform 0.5s ease-in-out;
        /* Agregamos una transición para el divDerecho */
    }
    .contenedorDivFechaMensaje {
        color: white;
        width: 80%;
    }
    .fechaCarruselAuto {
        text-align: left;
        font-size: 1em
    }
    .mensajeCarruselAuto {
        text-align: left;
        font-size: 1.5em
    }
    .divFechaMensaje.salida {
        width: 100%;
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .controladorCarruselAuto {
        position: absolute;
        bottom:5%;
        right: 5%;
        width: 6em;
        height: 2em;
    }
    .filaControl {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .itemControl {
        width: 0.70em;
        height: 0.70em
    }
    .itemControl:hover {
        background-color: grey;
    }
    .itemControl.resaltado {
        background-color: #cce5ff;
    }
}

@media (min-width: 1024px) {
    .carruselAuto {
        width: 100%;
        height: 35em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .divPrincipalCarruselAuto {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 2000px;
        overflow: hidden;
    }
    .divTituloCarruselAuto {
        position: absolute;
        left: 10%;
        top: 12.5%;
    }
    .tituloCarruselAuto {
        color: white;
        text-align: left;
        font-size: 3.5em;
    }
    .divFechaMensaje {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: transform 0.5s ease-in-out;
        /* Agregamos una transición para el divDerecho */
    }
    .contenedorDivFechaMensaje {
        color: white;
        width: 80%;
    }
    .fechaCarruselAuto {
        text-align: left;
        font-size: 1.25em
    }
    .mensajeCarruselAuto {
        text-align: left;
        font-size: 2em
    }
    .divFechaMensaje.salida {
        width: 100%;
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .controladorCarruselAuto {
        position: absolute;
        bottom:5%;
        right: 5%;
        width: 6em;
        height: 2em;
    }
    .filaControl {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .itemControl {
        width: 0.70em;
        height: 0.70em
    }
    .itemControl:hover {
        background-color: grey;
    }
    .itemControl.resaltado {
        background-color: #cce5ff;
    }
}

@media (min-width: 1280px) {
    .carruselAuto {
        width: 100%;
        height: 35em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .divPrincipalCarruselAuto {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 2000px;
        overflow: hidden;
    }
    .divTituloCarruselAuto {
        position: absolute;
        left: 10%;
        top: 12.5%;
    }
    .tituloCarruselAuto {
        color: white;
        text-align: left;
        font-size: 3.5em;
    }
    .divFechaMensaje {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: transform 0.5s ease-in-out;
        /* Agregamos una transición para el divDerecho */
    }
    .contenedorDivFechaMensaje {
        color: white;
        width: 80%;
    }
    .fechaCarruselAuto {
        text-align: left;
        font-size: 1.25em
    }
    .mensajeCarruselAuto {
        text-align: left;
        font-size: 2em
    }
    .divFechaMensaje.salida {
        width: 100%;
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .controladorCarruselAuto {
        position: absolute;
        bottom:5%;
        right: 5%;
        width: 6em;
        height: 2em;
    }
    .filaControl {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .itemControl {
        width: 0.70em;
        height: 0.70em
    }
    .itemControl:hover {
        background-color: grey;
    }
    .itemControl.resaltado {
        background-color: #cce5ff;
    }
}

@media (min-width: 1536px) {
    .carruselAuto {
        width: 100%;
        height: 35em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .divPrincipalCarruselAuto {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 2000px;
        overflow: hidden;
    }
    .divTituloCarruselAuto {
        position: absolute;
        left: 10%;
        top: 12.5%;
    }
    .tituloCarruselAuto {
        color: white;
        text-align: left;
        font-size: 3.5em;
    }
    .divFechaMensaje {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: transform 0.5s ease-in-out;
        /* Agregamos una transición para el divDerecho */
    }
    .contenedorDivFechaMensaje {
        color: white;
        width: 80%;
    }
    .fechaCarruselAuto {
        text-align: left;
        font-size: 1.25em
    }
    .mensajeCarruselAuto {
        text-align: left;
        font-size: 2em
    }
    .divFechaMensaje.salida {
        width: 100%;
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .controladorCarruselAuto {
        position: absolute;
        bottom:5%;
        right: 5%;
        width: 6em;
        height: 2em;
    }
    .filaControl {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .itemControl {
        width: 0.70em;
        height: 0.70em
    }
    .itemControl:hover {
        background-color: grey;
    }
    .itemControl.resaltado {
        background-color: #cce5ff;
    }
}
