.palabra {
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 4vw;
  color: white;
}
.contenedorFilas{
  width:100vw;
  max-width:2000px;
  height: 15vw
}

@media only screen and (min-width: 2500px) {
  .palabra {
    font-size: 5em;
    color:white
  }
  .contenedorFilas{
    height: 8vw
  }
}
.entrada-izquierda {
  transform: translateX(-100%);
  animation: entradaDesdeIzquierda 1s ease-in-out forwards;
}

@keyframes entradaDesdeIzquierda {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.entrada-abajo {
  transform: translateY(100%);
  animation: entradaDesdeAbajo 1.5s ease-in-out forwards;
}

@keyframes entradaDesdeAbajo {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

