/*
.NavBar {
    margin-bottom: 50px;
}

.barraEstatica {
    background-color: black;
    height: 65px;
    width: 100%;
    position: relative;
    left: 0;
    z-index: 1000;
    transition: top 0.3s;
}

.barraSticky {
    background-color: black;
    height: 65px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: top 0.3s;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.seccionBarra {
    width: 150px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
*/
.barraEstatica {
    height: 75px;
    width: 100%;
    position: relative;
    left: 0;
    z-index: 1000;
    transition: top 0.3s;
}

.barraSticky {
    height: 90px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: top 0.3s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: black;
}

.barraSeccionIzq {
    height: 100%;
}

.barraSeccionMedio {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.barraSeccionDer {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.seccionBarra {
    position: relative;
    width: 8.5em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 1em;
}

.logoLanders {
    position: relative;
    width: 100%;
    height: auto;
}
.iconoLanders {
    position: relative;
    width: 2.75em;
    height: auto;
}

.iconoLoginBarra{
    position: absolute;
    top: 1.85em;
    left: 1.75em;
}

.tituloLanders {
    color: #fff;
    font-size: 2.5em;
    margin: 0
}

.tituloSeccion {
    color: #fff;
    margin: 0;
    font-size: 0.80em
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 0;
    background-color: black;
    overflow-x: hidden;
    transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
    display: flex;
    color: white;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    text-align: left;
}

.contenedorBarraVertical {
    position: absolute;
    top: 15%;
    width: 100%;
    height: 80vh;
}

.seccionBarraVertical {
    width: 100%;
    height: 7vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tituloSeccionBarraVertical {
    position: relative;
    left: 5%;
    color: #fff;
    margin: 0;
}

.iconoSeccionBarraVertical {
    position: relative;
    right: 5%;
    color: #fff;
    margin: 0;
    font-weight: bold;
}

.overlay.show {
    width: 80%;
    right: 0;
}

.overlay.hide {
    width: 0;
    right: -80%;
}

@media (min-width: 475px) {
    .barraEstatica {
        height: 75px;
        width: 100%;
        position: relative;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
    }
    
    .barraSticky {
        height: 90px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: black;
    }
    
    .barraSeccionIzq {
        height: 100%;
    }
    
    .barraSeccionMedio {
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    
    .barraSeccionDer {
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    
    .seccionBarra {
        position: relative;
        width: 8.5em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 1em;
    }
    
    .logoLanders {
        position: relative;
        width: 100%;
        height: auto;
    }
    .iconoLanders {
        position: relative;
        width: 2.75em;
        height: auto;
    }
    
    .iconoLoginBarra{
        position: absolute;
        top: 1.85em;
        left: 1.75em;
    }
    
    .tituloLanders {
        color: #fff;
        font-size: 2.5em;
        margin: 0
    }
    
    .tituloSeccion {
        color: #fff;
        margin: 0;
        font-size: 0.80em
    }
    
    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 0;
        background-color: black;
        overflow-x: hidden;
        transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
        display: flex;
        color: white;
        z-index: 1000;
    
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    
    .contenedorBarraVertical {
        position: absolute;
        top: 15%;
        width: 100%;
        height: 80vh;
    }
    
    .seccionBarraVertical {
        width: 100%;
        height: 7vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .tituloSeccionBarraVertical {
        position: relative;
        left: 5%;
        color: #fff;
        margin: 0;
    }
    
    .iconoSeccionBarraVertical {
        position: relative;
        right: 5%;
        color: #fff;
        margin: 0;
        font-weight: bold;
    }
    
    .overlay.show {
        width: 80%;
        right: 0;
    }
    
    .overlay.hide {
        width: 0;
        right: -80%;
    }
}

@media (min-width: 540px) {
    .barraEstatica {
        height: 75px;
        width: 100%;
        position: relative;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
    }
    
    .barraSticky {
        height: 90px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: black;
    }
    
    .barraSeccionIzq {
        height: 100%;
    }
    
    .barraSeccionMedio {
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    
    .barraSeccionDer {
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    
    .seccionBarra {
        position: relative;
        width: 8.5em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 1em;
    }
    
    .logoLanders {
        position: relative;
        width: 100%;
        height: auto;
    }
    .iconoLanders {
        position: relative;
        width: 2.75em;
        height: auto;
    }
    
    .iconoLoginBarra{
        position: absolute;
        top: 1.85em;
        left: 1.75em;
    }
    
    .tituloLanders {
        color: #fff;
        font-size: 2.5em;
        margin: 0
    }
    
    .tituloSeccion {
        color: #fff;
        margin: 0;
        font-size: 0.80em
    }
    
    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 0;
        background-color: black;
        overflow-x: hidden;
        transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
        display: flex;
        color: white;
        z-index: 1000;
    
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    
    .contenedorBarraVertical {
        position: absolute;
        top: 15%;
        width: 100%;
        height: 80vh;
    }
    
    .seccionBarraVertical {
        width: 100%;
        height: 7vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .tituloSeccionBarraVertical {
        position: relative;
        left: 5%;
        color: #fff;
        margin: 0;
    }
    
    .iconoSeccionBarraVertical {
        position: relative;
        right: 5%;
        color: #fff;
        margin: 0;
        font-weight: bold;
    }
    
    .overlay.show {
        width: 80%;
        right: 0;
    }
    
    .overlay.hide {
        width: 0;
        right: -80%;
    }
}

@media (min-width: 768px) {
    .barraEstatica {
        height: 90px;
        width: 100%;
        position: relative;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
    }

    .barraSticky {
        height: 70px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .barraSeccionIzq {
        height: 100%;
    }

    .barraSeccionMedio {
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .barraSeccionDer {
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .seccionBarra {
        position: relative;
        width: 8.5em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: unset;
    }

    .logoLanders {
        position: relative;
        width: 90%;
        height: auto;
    }
    .iconoLanders {
        position: relative;
        width: 2.75em;
        height: auto;
    }

    .iconoLoginBarra{
        position: absolute;
        top: 1.85em;
        left: 1.75em;
    }

    .tituloLanders {
        color: #fff;
        font-size: 2.5em;
        margin: 0
    }

    .tituloSeccion {
        color: #fff;
        margin: 0;
        font-size: 0.80em
    }

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 0;
        background-color: black;
        overflow-x: hidden;
        transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
        display: flex;
        color: white;
        z-index: 1000;

        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .contenedorBarraVertical {
        position: absolute;
        top: 15%;
        width: 100%;
        height: 80vh;
    }

    .seccionBarraVertical {
        width: 100%;
        height: 7vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .tituloSeccionBarraVertical {
        position: relative;
        left: 5%;
        color: #fff;
        margin: 0;
    }

    .iconoSeccionBarraVertical {
        position: relative;
        right: 5%;
        color: #fff;
        margin: 0;
        font-weight: bold;
    }

    .overlay.show {
        width: 80%;
        right: 0;
    }

    .overlay.hide {
        width: 0;
        right: -80%;
    }
}

@media (min-width: 1024px) {
    .barraEstatica {
        height: 90px;
        width: 100%;
        position: relative;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
    }

    .barraSticky {
        height: 70px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .barraSeccionIzq {
        height: 100%;
    }

    .barraSeccionMedio {
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .barraSeccionDer {
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .seccionBarra {
        position: relative;
        width: 8.5em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: unset;
    }

    .logoLanders {
        position: relative;
        width: 90%;
        height: auto;
    }
    .iconoLanders {
        position: relative;
        width: 2.75em;
        height: auto;
    }

    .iconoLoginBarra{
        position: absolute;
        top: 1.85em;
        left: 1.75em;
    }

    .tituloLanders {
        color: #fff;
        font-size: 2.5em;
        margin: 0
    }

    .tituloSeccion {
        color: #fff;
        margin: 0;
        font-size: 0.80em
    }

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 0;
        background-color: black;
        overflow-x: hidden;
        transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
        display: flex;
        color: white;
        z-index: 1000;

        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .contenedorBarraVertical {
        position: absolute;
        top: 15%;
        width: 100%;
        height: 80vh;
    }

    .seccionBarraVertical {
        width: 100%;
        height: 7vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .tituloSeccionBarraVertical {
        position: relative;
        left: 5%;
        color: #fff;
        margin: 0;
    }

    .iconoSeccionBarraVertical {
        position: relative;
        right: 5%;
        color: #fff;
        margin: 0;
        font-weight: bold;
    }

    .overlay.show {
        width: 80%;
        right: 0;
    }

    .overlay.hide {
        width: 0;
        right: -80%;
    }
}

@media (min-width: 1280px) {
    .barraEstatica {
        height: 90px;
        width: 100%;
        position: relative;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
    }

    .barraSticky {
        height: 90px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .barraSeccionIzq {
        height: 100%;
    }

    .barraSeccionMedio {
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .barraSeccionDer {
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .seccionBarra {
        position: relative;
        width: 12em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: unset;
    }

    .logoLanders {
        position: relative;
        width: 90%;
        height: auto;
    }
    .iconoLanders {
        position: relative;
        width: 4em;
        height: auto;
    }

    .iconoLoginBarra{
        position: absolute;
        top: 2.2em;
        left: 3em;
    }

    .tituloLanders {
        color: #fff;
        font-size: 2.5em;
        margin: 0
    }

    .tituloSeccion {
        color: #fff;
        margin: 0;
        font-size: 1em
    }

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 0;
        background-color: black;
        overflow-x: hidden;
        transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
        display: flex;
        color: white;
        z-index: 1000;

        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .contenedorBarraVertical {
        position: absolute;
        top: 15%;
        width: 100%;
        height: 80vh;
    }

    .seccionBarraVertical {
        width: 100%;
        height: 7vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .tituloSeccionBarraVertical {
        position: relative;
        left: 5%;
        color: #fff;
        margin: 0;
    }

    .iconoSeccionBarraVertical {
        position: relative;
        right: 5%;
        color: #fff;
        margin: 0;
        font-weight: bold;
    }

    .overlay.show {
        width: 80%;
        right: 0;
    }

    .overlay.hide {
        width: 0;
        right: -80%;
    }
}

@media (min-width: 1560px) {
    .barraEstatica {
        height: 90px;
        width: 100%;
        position: relative;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
    }

    .barraSticky {
        height: 90px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        transition: top 0.3s;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .barraSeccionIzq {
        height: 100%;
    }

    .barraSeccionMedio {
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .barraSeccionDer {
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .seccionBarra {
        position: relative;
        width: 12em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: unset;
    }

    .logoLanders {
        position: relative;
        width: 90%;
        height: auto;
    }
    .iconoLanders {
        position: relative;
        width: 4em;
        height: auto;
    }

    .iconoLoginBarra{
        position: absolute;
        top: 2.2em;
        left: 3em;
    }

    .tituloLanders {
        color: #fff;
        font-size: 2.5em;
        margin: 0
    }

    .tituloSeccion {
        color: #fff;
        margin: 0;
        font-size: 1em
    }

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 0;
        background-color: black;
        overflow-x: hidden;
        transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
        display: flex;
        color: white;
        z-index: 1000;

        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .contenedorBarraVertical {
        position: absolute;
        top: 15%;
        width: 100%;
        height: 80vh;
    }

    .seccionBarraVertical {
        width: 100%;
        height: 7vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .tituloSeccionBarraVertical {
        position: relative;
        left: 5%;
        color: #fff;
        margin: 0;
    }

    .iconoSeccionBarraVertical {
        position: relative;
        right: 5%;
        color: #fff;
        margin: 0;
        font-weight: bold;
    }

    .overlay.show {
        width: 80%;
        right: 0;
    }

    .overlay.hide {
        width: 0;
        right: -80%;
    }
}