@media only screen and (min-width: 435px){
.contenedorGrid {
  width: 100%;
  display: flex;
  justify-content: center; /* Establece el ancho máximo para la versión móvil */
}

.elementoGrid {
  height: 30vh;
  box-sizing: border-box;
}
}

@media only screen and (min-width: 50px) and (max-width: 435px) {
  .contenedorGrid { /* Establece el ancho máximo para la versión móvil */
    width: 100%;
  }
  .elementoGrid {
    height: 30vh;
    box-sizing: border-box;
  }
}