/* Celular */
.Footer {
    width: 100%;
    height: 55em;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.divPrincipalFooter {
    position: relative;
    width: 100%;
    max-width: 2000px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.divsVerticales {
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;
    overflow: hidden;
}

.contenedorDivsVerticales {
    position: absolute;
    display: flex;
    height: 100%;
}

.NavFooter {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1
}

.divTituloNavFooter {
    position: relative;
    width: 100%;
    height: 5em;
    display: flex;
    align-items: center;
}

.tituloNavFooter {
    position: absolute;
    right: 1.5em;
    font-size: 1.5em;
    color: white
}

.divBloquesLinksNavFooter {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.BloqueUnoLinksNavFooter {
    position: relative;
    right: 2.5em;
    display: flex;
    flex-direction: column;
    text-align: right;
    color: white;
}

.BloqueDosLinksNavFooter {
    position: relative;
    right: 2.5em;
    display: flex;
    flex-direction: column;
    text-align: right;
    color: white;
}

.LinkNavFooter {
    margin-bottom: 0.75em;
}
.divCopyright{
    position: relative;
    width: 100%;
    height: 5em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: right;
    right: 2.5em;
}
.copyright{
    color:white;
    font-size: 0.75em;
}

/* CAMBIO */
.divTresPalabrasCambio {
    position: relative;
    height: 100%;
    overflow: hidden;
    margin-right: 4px;
}

.palabraCambioUno {
    position: absolute;
    top: 0;
    right: -80px;
    width: 90px;
    height: 100%;
    color: white;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    white-space: nowrap;
    font-size: 55px;
}

.palabraCambioDos {
    position: absolute;
    width: 90px;
    height: 100%;
    right: -10px;
    color: white;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    white-space: nowrap;
    font-size: 55px;
}

.palabraCambioTres {
    position: absolute;
    top: 0;
    right: 60px;
    width: 90px;
    height: 100%;
    color: white;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    white-space: nowrap;
    font-size: 55px;
}


@media (min-width: 475px) {
    .Footer {
        width: 100%;
        height: 65em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .divPrincipalFooter {
        position: relative;
        width: 100%;
        max-width: 2000px;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    
    .divsVerticales {
        position: relative;
        display: flex;
        flex: 1;
        height: 100%;
        overflow: hidden;
    }
    
    .contenedorDivsVerticales {
        position: absolute;
        display: flex;
        height: 100%;
    }
    
    .NavFooter {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1
    }
    
    .divTituloNavFooter {
        position: relative;
        width: 100%;
        height: 5em;
        display: flex;
        align-items: center;
    }
    
    .tituloNavFooter {
        position: absolute;
        right: 1.5em;
        font-size: 2em;
        color: white
    }
    
    .divBloquesLinksNavFooter {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .BloqueUnoLinksNavFooter {
        position: relative;
        right: 2.5em;
        display: flex;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .BloqueDosLinksNavFooter {
        position: relative;
        right: 2.5em;
        display: flex;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .LinkNavFooter {
        margin-bottom: 0.5em;
        font-size: 1.25em;
    }

    .divCopyright{
        position: relative;
        width: 100%;
        height: 5em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        right: 2.5em;
    }
    .copyright{
        color:white;
        font-size: 1em;
    }
    
    /* CAMBIO */
    .divTresPalabrasCambio {
        position: relative;
        height: 100%;
        overflow: hidden;
        margin-right: 4px;
    }
    
    .palabraCambioUno {
        position: absolute;
        top: 0;
        right: -95px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 75px;
    }
    
    .palabraCambioDos {
        position: absolute;
        width: 90px;
        height: 100%;
        right: -10px;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 75px;
    }
    
    .palabraCambioTres {
        position: absolute;
        top: 0;
        right: 75px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 75px;
    }
}

@media (min-width: 640px) {
    .Footer {
        width: 100%;
        height: 65em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .divPrincipalFooter {
        position: relative;
        width: 100%;
        max-width: 2000px;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    
    .divsVerticales {
        position: relative;
        display: flex;
        flex: 1;
        height: 100%;
        overflow: hidden;
    }
    
    .contenedorDivsVerticales {
        position: absolute;
        display: flex;
        height: 100%;
    }
    
    .NavFooter {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1
    }
    
    .divTituloNavFooter {
        position: relative;
        width: 100%;
        height: 5em;
        display: flex;
        align-items: center;
    }
    
    .tituloNavFooter {
        position: absolute;
        right: 1.75em;
        font-size: 2.5em;
        color: white
    }
    
    .divBloquesLinksNavFooter {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
    }
    
    .BloqueUnoLinksNavFooter {
        position: relative;
        right: unset;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .BloqueDosLinksNavFooter {
        position: relative;
        right: unset;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .LinkNavFooter {
        font-size: 1.25em;
        margin-bottom: 0.75em;
    }
    .divCopyright{
        position: relative;
        width: 100%;
        height: 5em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        right: 5em;
    }
    .copyright{
        color:white;
        font-size: 0.90em;
    }
    
    
    /* CAMBIO */
    .divTresPalabrasCambio {
        position: relative;
        height: 100%;
        overflow: hidden;
        margin-right: 7px;
    }
    
    .palabraCambioUno {
        position: absolute;
        top: 0;
        right: -110px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 90px;
    }
    
    .palabraCambioDos {
        position: absolute;
        width: 90px;
        height: 100%;
        right: -5px;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 90px;
    }
    
    .palabraCambioTres {
        position: absolute;
        top: 0;
        right: 100px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size:90px;
    }
}

@media (min-width: 768px) {
    .Footer {
        width: 100%;
        height: 65em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .divPrincipalFooter {
        position: relative;
        width: 100%;
        max-width: 2000px;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    
    .divsVerticales {
        position: relative;
        display: flex;
        flex: 1;
        height: 100%;
        overflow: hidden;
    }
    
    .contenedorDivsVerticales {
        position: absolute;
        display: flex;
        height: 100%;
    }
    
    .NavFooter {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1
    }
    
    .divTituloNavFooter {
        position: relative;
        width: 100%;
        height: 7.5em;
        display: flex;
        align-items: center;
    }
    
    .tituloNavFooter {
        position: absolute;
        right: 1.75em;
        font-size: 3em;
        color: white
    }
    
    .divBloquesLinksNavFooter {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
    }
    
    .BloqueUnoLinksNavFooter {
        position: relative;
        right: unset;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .BloqueDosLinksNavFooter {
        position: relative;
        right: unset;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .LinkNavFooter {
        font-size: 1.5em;
        margin-bottom: 0.75em;
    }
    .divCopyright{
        position: relative;
        width: 100%;
        height: 5em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        right: 5em;
    }
    .copyright{
        color:white;
        font-size: 1em;
    }
    
    /* CAMBIO */
    .divTresPalabrasCambio {
        position: relative;
        height: 100%;
        overflow: hidden;
        margin-right: 4px;
    }
    
    .palabraCambioUno {
        position: absolute;
        top: 0;
        right: -110px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 95px;
    }
    
    .palabraCambioDos {
        position: absolute;
        width: 90px;
        height: 100%;
        right: 5px;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 95px;
    }
    
    .palabraCambioTres {
        position: absolute;
        top: 0;
        right: 120px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 95px;
    }
}

@media (min-width: 1024px) {
    .Footer {
        width: 100%;
        height: 45em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .divPrincipalFooter {
        position: relative;
        width: 100%;
        max-width: 2000px;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .divsVerticales {
        position: relative;
        display: flex;
        flex: 1;
        height: 100%;
        overflow: hidden;
    }
    
    .contenedorDivsVerticales {
        position: absolute;
        display: flex;
        height: 100%;
    }
    
    .NavFooter {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1
    }
    
    .divTituloNavFooter {
        position: absolute;
        right: 0;
        width: 35em;
        height: 7.5em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .tituloNavFooter {
        position: relative;
        right: unset;
        font-size: 3em;
        color: white
    }
    
    .divBloquesLinksNavFooter {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .BloqueUnoLinksNavFooter {
        position: relative;
        right: 5em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .BloqueDosLinksNavFooter {
        position: relative;
        right: 5em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .LinkNavFooter {
        font-size: 1.5em;
        margin-bottom: 0.5em;
    }
    
    .divCopyright{
        position: relative;
        width: 100%;
        height: 5em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        right: 5em;
    }
    .copyright{
        color:white;
        font-size: 1.25em;
    }
    /* CAMBIO */
    .divTresPalabrasCambio {
        position: relative;
        height: 100%;
        overflow: hidden;
        margin-right: 10px;
    }
    
    .palabraCambioUno {
        position: absolute;
        top: 0;
        right: -110px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 110px;
    }
    
    .palabraCambioDos {
        position: absolute;
        width: 90px;
        height: 100%;
        right: 25px;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 110px;
    }
    
    .palabraCambioTres {
        position: absolute;
        top: 0;
        right: 170px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 110px;
    }
}

@media (min-width: 1280px) {
    .Footer {
        width: 100%;
        height: 45em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .divPrincipalFooter {
        position: relative;
        width: 100%;
        max-width: 2000px;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .divsVerticales {
        position: relative;
        display: flex;
        flex: 1.25;
        height: 100%;
        overflow: hidden;
    }
    
    .contenedorDivsVerticales {
        position: absolute;
        display: flex;
        height: 100%;
    }
    
    .NavFooter {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 0.75
    }
    
    .divTituloNavFooter {
        position: absolute;
        right: 0;
        width: 40em;
        height: 7.5em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .tituloNavFooter {
        position: relative;
        right: unset;
        font-size: 3em;
        color: white
    }
    
    .divBloquesLinksNavFooter {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .BloqueUnoLinksNavFooter {
        position: relative;
        right: 8em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .BloqueDosLinksNavFooter {
        position: relative;
        right: 8em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .LinkNavFooter {
        font-size: 1.5em;
        margin-bottom: 0.5em;
    }

    .divCopyright{
        position: relative;
        width: 100%;
        height: 5em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        right: 8em;
    }
    .copyright{
        color:white;
        font-size: 1.40em;
    }
    
    .divTresPalabrasCambio {
        position: relative;
        height: 100%;
        overflow: hidden;
        margin-right: 10px;
    }
    
    .palabraCambioUno {
        position: absolute;
        top: 0;
        right: -110px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 110px;
    }
    
    .palabraCambioDos {
        position: absolute;
        width: 90px;
        height: 100%;
        right: 25px;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 110px;
    }
    
    .palabraCambioTres {
        position: absolute;
        top: 0;
        right: 170px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 110px;
    }
}

@media (min-width: 1560px) {
    .Footer {
        width: 100%;
        height: 45em;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .divPrincipalFooter {
        position: relative;
        width: 100%;
        max-width: 2000px;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .divsVerticales {
        position: relative;
        display: flex;
        flex: 1.25;
        height: 100%;
        overflow: hidden;
    }
    
    .contenedorDivsVerticales {
        position: absolute;
        display: flex;
        height: 100%;
    }
    
    .NavFooter {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 0.75
    }
    
    .divTituloNavFooter {
        position: absolute;
        right: 0;
        width: 40em;
        height: 7.5em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .tituloNavFooter {
        position: relative;
        right: unset;
        font-size: 3.5em;
        color: white
    }
    
    .divBloquesLinksNavFooter {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .BloqueUnoLinksNavFooter {
        position: relative;
        right: 8em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .BloqueDosLinksNavFooter {
        position: relative;
        right: 8em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        color: white;
    }
    
    .LinkNavFooter {
        font-size: 1.75em;
        margin-bottom: 0.35em;
    }
    .divCopyright{
        position: relative;
        width: 100%;
        height: 5em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        right: 8em;
    }
    .copyright{
        color:white;
        font-size: 1.40em;
    }
    
    .divTresPalabrasCambio {
        position: relative;
        height: 100%;
        overflow: hidden;
        margin-right: 15px;
    }
    
    .palabraCambioUno {
        position: absolute;
        top: 0;
        right: -140px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 120px;
    }
    
    .palabraCambioDos {
        position: absolute;
        width: 90px;
        height: 100%;
        right: 25px;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 120px;
    }
    
    .palabraCambioTres {
        position: absolute;
        top: 0;
        right: 170px;
        width: 90px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 120px;
    }
}






/*
@media only screen and (min-width: 2500px) {
    .Footer {
        width: 100%;
        height: 40vh;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .divPrincipalFooter {

        position: relative;
        width: 100%;
        max-width: 2000px;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .divsVerticales {
        position: relative;
        display: flex;
        flex: 1;
        height: 100%;
        overflow: hidden;
    }

    .contenedorDivsVerticales {
        position: absolute;
        display: flex;
        height: 100%;
    }

    .NavFooter {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1
    }

    .divTituloNavFooter {
        flex: 1;
        position: relative;
        width: 100%;
        height: 100%
    }

    .tituloNavFooter {
        position: absolute;
        right: 12.5%;
        top: 15%;
        font-size: 1.5vw;
        color: white
    }

    .divBloquesLinksNavFooter {
        flex: 1;
        position: relative;
        width: 100%;
        height: 100%;
        justify-content: space-around;
        display: flex
    }

    .BloqueUnoLinksNavFooter {
        display: flex;
        flex-direction: column;
        text-align: right;
        color: white;
        font-size: 0.75vw;
    }

    .BloqueDosLinksNavFooter {
        display: flex;
        flex-direction: column;
        text-align: right;
        color: white;
        font-size: 0.75vw;
    }

    .divTresPalabrasCambio {
        position: relative;
        height: 100%;
        overflow: hidden;
        margin-right: 20px;
    }

    .palabraCambioUno {
        position: absolute;
        top: 0;
        right: -130px;
        width: 40px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 145px;
    }

    .palabraCambioDos {
        width: 40px;
        height: 100%;
        right: 50px;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 145px;
    }

    .palabraCambioTres {
        position: absolute;
        top: 0;
        right: 200px;
        width: 40px;
        height: 100%;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        white-space: nowrap;
        font-size: 145px;
    }
}*/
