.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a{
  text-decoration: none;
  color: inherit;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.BotonIniciar {

  display: flex;
  width: 100%;
  height: 3vw;
  max-height: 35px;
  justify-content: center;
  margin-bottom: 50px
}

.ContenedorBoton {
  display: flex;
  width: 25vw;
  max-width: 500px;

  align-items: center;
  justify-content: space-around;
  color: white
}

.TituloBoton {
  font-size: 2vw;
  font-weight: 500;
}

.IconoBoton {
  position: relative;
  width: 1.85vw;
  height: 1.85vw;
  background-color: #01ccc8;
  font-weight: 700;
  font-size: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer
}

.iconoFlechaDerecha{
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 2500px) {
  .TituloBoton {
    font-size: 0.5vw;
    font-weight: 500
  }
  .IconoBoton {
    width: 0.5vw;
    height: 0.5vw;
    font-size: 0.5vw;
  }
  .iconoFlechaBoton{
    height: 100%;
    width: 100%;
  }
}
@media only screen and 
(min-width: 540px) and 
(max-width: 1200px) and 
(min-height: 935px) and 
(max-height: 1200px){
  .ContenedorBoton {
    display: flex;
    width: 40vw;
    max-width: 500px;
  
    align-items: center;
    justify-content: space-around;
    color: white;
  }
  
  .TituloBoton {
    font-size: 3.5vw;
    font-weight: 400
  }
  
  .IconoBoton {
    position: relative;
    width: 3.5vw;
    height: 3.5vw;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 3.5vw;
    cursor: pointer
  }
  .iconoFlechaDerecha{
    height: 100%;
    width: 100%;
  }
}

@media only screen and 
(min-width: 50px) and 
(max-width: 540px) and 
(min-height: 300px) and 
(max-height: 935px){
  .ContenedorBoton {
    display: flex;
    width: 45vw;
    max-width: 300px;
  
    align-items: center;
    justify-content: space-around;
    color: white
  }
  
  .TituloBoton {
    font-size: 4vw;
    font-weight: 400
  }
  
  .IconoBoton {
    position: relative;
    width: 4vw;
    height: 4vw;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 4vw;
    cursor: pointer
  }
  .iconoFlechaDerecha{
    height: 100%;
    width: 100%;
  }
}