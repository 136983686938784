@media only screen and (min-width: 435px) {
    .mi-div-entero {
        min-width: 250px;
        max-width: 275px;
        width: calc(25% - 20px);
        min-height: 425px;
        margin: 20px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        /* Reemplaza con la ruta correcta a tu imagen */
        background-color: #03787f;
        transition: transform 0.3s ease-in-out;
    }

    .mi-div-entero:hover {
        transform: scale(1.1);
    }

    .mi-div-entero::before {
        content: "";
        position: absolute;
        top: -5%;
        left: -5%;
        right: -5%;
        bottom: -5%;
        background-image: inherit;
        background-size: cover;
        background-position: center;
        transform: scale(1);
        transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
    }

    .mi-div-entero:hover::before {
        transform: scale(2);
        filter: blur(10px);
        /* Ajusta el valor según el desenfoque deseado */
    }

    .contenido-entero {
        position: relative;
    }

    h2,
    h3,
    p {
        margin: 0;
    }

    .descripcion-entero {
        position: absolute;
        top: 150px;
        left: 175%;
        width: 100%;
        height: 100%;
        color: black;
        transition: left 0.3s ease-in-out;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    .descripcion-entero.visible-entero {
        left: 0;
    }

    .expandir-entero {
        position: absolute;
        top: 500px;
        left: 0;
        width: 100%;
        height: 50px;
        color: black;
        transition: top 0.3s ease-in-out;
        overflow: hidden;
        cursor: pointer;
    }

    .expandir-entero.visible-entero {
        top: 380px;
        cursor: pointer;
    }
}

@media only screen and (min-width: 50px) and (max-width: 435px) {
    .mi-div-entero {
        min-height: 425px;
        margin: 10px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        /* Reemplaza con la ruta correcta a tu imagen */
        background-color: #03787f;
        transition: transform 0.3s ease-in-out;
    }

    .mi-div-entero:hover {
        transform: scale(1.1);
    }

    .mi-div-entero::before {
        content: "";
        position: absolute;
        top: -5%;
        left: -5%;
        right: -5%;
        bottom: -5%;
        background-image: inherit;
        background-size: cover;
        background-position: center;
        transform: scale(1);
        transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
    }

    .mi-div-entero:hover::before {
        transform: scale(2);
        filter: blur(10px);
        /* Ajusta el valor según el desenfoque deseado */
    }

    .contenido-entero {
        position: relative;
        color: black;
    }

    h2,
    h3,
    p {
        margin: 0;
    }

    .descripcion-entero {
        position: absolute;
        top: 150px;
        left: 175%;
        width: 100%;
        height: 100%;
        color: black;
        transition: left 0.3s ease-in-out;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    .descripcion-entero.visible-entero {
        left: 0;
    }

    .expandir-entero {
        position: absolute;
        top: 500px;
        left: 0;
        width: 100%;
        height: 50px;
        color: black;
        transition: top 0.3s ease-in-out;
        overflow: hidden;
        cursor: pointer;
    }

    .expandir-entero.visible-entero {
        top: 380px;
        cursor: pointer;
    }
}