.estiloDivPrincipalFotoReduccion {
  height: 50em;
  width: 100%;
  position: relative;

  margin-bottom: 50px;

}
.estiloDivStickyFotoReduccion {
  display: none;
  top: 0;
  background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
  background-size: cover;
  opacity: 1;
  z-index: 2;
}
.estiloDivEstaticoFotoReduccion {
  height: 50em;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
  opacity: 1;
  z-index: 1;
}
.contenedorDivEstatico {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  max-width: 2000px;
  height: 100%;
}
.divEstaticoIzquierda {
  position: relative;
  flex: 1;
  flex-direction: column;
  height: 100%;
}
.imagenDivEstaticoIzquierda {
  display: flex;
  background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
  background-size: cover;
  height: 100%
}
.tituloDivEstaticoIzquierda {
  display: none;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 100%;
  font-size: 4vw;
  color: white
}
.divEstaticoDerecha {
  position: relative;
  display: flex;
  flex: 1;
 
}
.contenedorDivEstaticoDerecha {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease-out, transform 0.5s ease-out;
  
}
.contenedortituloDescripcionDivEstaticoDerecha {
  position: relative;
  color: white;
  height: 15em;
  width: 80vw;
  display: flex;
  flex-direction: column;

}
.tituloDivEstaticoDerecha {
  flex: 0.5;
  text-align: left;
  font-size: 1em
}  
.descripcionDivEstaticoDerecha {
  flex: 1;
  display: flex;
  text-align: left;
  font-size: 1em;
  align-items: center;
}
.expandirDivEstaticoDerecha {
  flex: 0.5;
  display: flex;
  font-size: 1em;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.expandirFotoReduccion {
  display: flex;
  width: 175px;
  align-items: center;
  color: white
}
.tituloBotonExpandirFotoReduccion {
  font-size: 1em;
  font-weight: bold;
  text-align: left;
  margin-right: 20px
}
.iconoBotonExpandirFotoReduccion {
  position: relative;
  width: 1em;
  height: 1em;
  background-color: #01ccc8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 1em;
  cursor: pointer
}
@media (min-width: 475px) {
  .estiloDivPrincipalFotoReduccion {
    height: 50em;
    width: 100%;
    position: relative;
  
    margin-bottom: 50px;
  }
  .estiloDivStickyFotoReduccion {
    display: none;
    top: 0;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    opacity: 1;
    z-index: 2;
  }
  .estiloDivEstaticoFotoReduccion {
    height: 50em;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 0;
    opacity: 1;
    z-index: 1;
  }
  .contenedorDivEstatico {
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    max-width: 2000px;
    height: 100%;
  }
  .divEstaticoIzquierda {
    position: relative;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }
  .imagenDivEstaticoIzquierda {
    display: flex;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    height: 100%
  }
  .tituloDivEstaticoIzquierda {
    display: none;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 100%;
    font-size: 4vw;
    color: white
  }
  .divEstaticoDerecha {
    position: relative;
    display: flex;
    flex: 1;
   
  }
  .contenedorDivEstaticoDerecha {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 1s ease-out, transform 0.5s ease-out;
    
  }
  .contenedortituloDescripcionDivEstaticoDerecha {
    position: relative;
    color: white;
    height: 15em;
    width: 80vw;
    display: flex;
    flex-direction: column;
  
  }
  .tituloDivEstaticoDerecha {
    flex: 0.5;
    text-align: left;
    font-size: 1.25em
  }  
  .descripcionDivEstaticoDerecha {
    flex: 1;
    display: flex;
    text-align: left;
    font-size: 1em;
    align-items: center;
  }
  .expandirDivEstaticoDerecha {
    flex: 0.5;
    display: flex;
    font-size: 1em;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  .expandirFotoReduccion {
    display: flex;
    width: 175px;
    align-items: center;
    color: white
  }
  .tituloBotonExpandirFotoReduccion {
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    margin-right: 20px
  }
  .iconoBotonExpandirFotoReduccion {
    position: relative;
    width: 1em;
    height: 1em;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1em;
    cursor: pointer
  }
}

@media (min-width: 640px) {
  .estiloDivPrincipalFotoReduccion {
    height: 55em;
    width: 100%;
    position: relative;
  
    margin-bottom: 50px;
  }
  .estiloDivStickyFotoReduccion {
    display: none;
    top: 0;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    opacity: 1;
    z-index: 2;
  }
  .estiloDivEstaticoFotoReduccion {
    height: 55em;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 0;
    opacity: 1;
    z-index: 1;
  }
  .contenedorDivEstatico {
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    max-width: 2000px;
    height: 100%;
  }
  .divEstaticoIzquierda {
    position: relative;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }
  .imagenDivEstaticoIzquierda {
    display: flex;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    height: 100%
  }
  .tituloDivEstaticoIzquierda {
    display: none;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 100%;
    font-size: 4vw;
    color: white
  }
  .divEstaticoDerecha {
    position: relative;
    display: flex;
    flex: 1;
   
  }
  .contenedorDivEstaticoDerecha {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 1s ease-out, transform 0.5s ease-out;
    
  }
  .contenedortituloDescripcionDivEstaticoDerecha {
    position: relative;
    color: white;
    height: 20em;
    width: 80vw;
    display: flex;
    flex-direction: column;
  
  }
  .tituloDivEstaticoDerecha {
    flex: 0.5;
    text-align: left;
    font-size: 1.5em
  }  
  .descripcionDivEstaticoDerecha {
    flex: 1;
    display: flex;
    text-align: left;
    font-size: 1.25em;
    align-items: center;
  }
  .expandirDivEstaticoDerecha {
    flex: 0.5;
    display: flex;
    font-size: 1.25em;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  .expandirFotoReduccion {
    display: flex;
    width: 175px;
    align-items: center;
    color: white
  }
  .tituloBotonExpandirFotoReduccion {
    font-size: 1.25em;
    font-weight: bold;
    text-align: left;
    margin-right: 20px
  }
  .iconoBotonExpandirFotoReduccion {
    position: relative;
    width: 1.25em;
    height: 1.25em;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1.25em;
    cursor: pointer
  }
}

@media (min-width: 768px) {
  .estiloDivPrincipalFotoReduccion {
    height: 55em;
    width: 100%;
    position: relative;
  
    margin-bottom: 50px;
  }
  .estiloDivStickyFotoReduccion {
    display: none;
    top: 0;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    opacity: 1;
    z-index: 2;
  }
  .estiloDivEstaticoFotoReduccion {
    height: 55em;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 0;
    opacity: 1;
    z-index: 1;
  }
  .contenedorDivEstatico {
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    max-width: 2000px;
    height: 100%;
  }
  .divEstaticoIzquierda {
    position: relative;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }
  .imagenDivEstaticoIzquierda {
    display: flex;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    height: 100%
  }
  .tituloDivEstaticoIzquierda {
    display: none;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 100%;
    font-size: 4vw;
    color: white
  }
  .divEstaticoDerecha {
    position: relative;
    display: flex;
    flex: 1;
   
  }
  .contenedorDivEstaticoDerecha {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 1s ease-out, transform 0.5s ease-out;
    
  }
  .contenedortituloDescripcionDivEstaticoDerecha {
    position: relative;
    color: white;
    height: 20em;
    width: 80vw;
    display: flex;
    flex-direction: column;
  
  }
  .tituloDivEstaticoDerecha {
    flex: 0.5;
    text-align: left;
    font-size: 1.75em
  }  
  .descripcionDivEstaticoDerecha {
    flex: 1;
    display: flex;
    text-align: left;
    font-size: 1.25em;
    align-items: center;
  }
  .expandirDivEstaticoDerecha {
    flex: 0.5;
    display: flex;
    font-size: 1.25em;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  .expandirFotoReduccion {
    display: flex;
    width: 175px;
    align-items: center;
    color: white
  }
  .tituloBotonExpandirFotoReduccion {
    font-size: 1.25em;
    font-weight: bold;
    text-align: left;
    margin-right: 20px
  }
  .iconoBotonExpandirFotoReduccion {
    position: relative;
    width: 1.25em;
    height: 1.25em;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1.25em;
    cursor: pointer
  }
}

@media (min-width: 1024px) {
  @keyframes entradaDesdeDerecha {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
  
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .estiloDivPrincipalFotoReduccion {
    width: 100%;
    height: 200vh;
    position: relative;
  
    margin-bottom: 50px;
  }
  
  .estiloDivStickyFotoReduccion {
    display: block;
    top: 0;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    opacity: 1;
    z-index: 2;
  }
  
  .estiloDivEstaticoFotoReduccion {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    bottom: 0;
    opacity: 1;
    z-index: 1;
  }
  
  .contenedorDivEstatico {
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    max-width: 2000px;
    height: 100%;
  }
  
  .divEstaticoIzquierda {
    position: relative;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }
  
  .imagenDivEstaticoIzquierda {
    display: flex;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    height: 80%
  }
  
  .tituloDivEstaticoIzquierda {
    display: flex;
    height: 20%;
    width: 100%;
    text-align: right;
    align-items: center;
    font-size: 2.15em;
    color: white
  }
  
  
  .divEstaticoDerecha {
    display: flex;
    flex: 1
  }
  
  .contenedorDivEstaticoDerecha {
    width: 100%;
    display: flex;
    transition: opacity 1s ease-out, transform 0.5s ease-out;
    justify-content: unset;
    align-items: unset;
  }
  
  .contenedortituloDescripcionDivEstaticoDerecha {
    position: relative;
    top: 13em;
    left: 7em;
    color: white;
    height: 20em;
    width: 25em;
    display: flex;
    flex-direction: column;
  }
  
  .tituloDivEstaticoDerecha {
    flex: 0.5;
    text-align: left;
    font-size: 1.25em
  }
  
  .descripcionDivEstaticoDerecha {
    flex: 1;
    display: flex;
    text-align: left;
    font-size: 1.25em;
    align-items: center;
  }
  
  .expandirDivEstaticoDerecha {
    flex: 0.5;
    display: flex;
    font-size: 1.15em;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  
  .expandirFotoReduccion {
    display: flex;
    width: 175px;
    align-items: center;
    color: white
  }
  
  .tituloBotonExpandirFotoReduccion {
    font-size: 1.15em;
    font-weight: bold;
    text-align: left;
    margin-right: 20px
  }
  
  .iconoBotonExpandirFotoReduccion {
    position: relative;
    width: 1.15em;
    height: 1.15em;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1em;
    cursor: pointer
  }
}

@media (min-width: 1280px) {
  @keyframes entradaDesdeDerecha {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
  
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .estiloDivPrincipalFotoReduccion {
    width: 100%;
    height: 200vh;
    position: relative;
  
    margin-bottom: 50px;
  }
  
  .estiloDivStickyFotoReduccion {
    display: block;
    top: 0;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    opacity: 1;
    z-index: 2;
  }
  
  .estiloDivEstaticoFotoReduccion {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    bottom: 0;
    opacity: 1;
    z-index: 1;
  }
  
  .contenedorDivEstatico {
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    max-width: 2000px;
    height: 100%;
  }
  
  .divEstaticoIzquierda {
    position: relative;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }
  
  .imagenDivEstaticoIzquierda {
    display: flex;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    height: 80%
  }
  
  .tituloDivEstaticoIzquierda {
    display: flex;
    height: 20%;
    width: 100%;
    text-align: right;
    align-items: center;
    font-size: 2.35em;
    color: white
  }
  
  
  .divEstaticoDerecha {
    display: flex;
    flex: 1
  }
  
  .contenedorDivEstaticoDerecha {
    width: 100%;
    display: flex;
    transition: opacity 1s ease-out, transform 0.5s ease-out;
    justify-content: unset;
    align-items: unset;
  }
  
  .contenedortituloDescripcionDivEstaticoDerecha {
    position: relative;
    top: 13em;
    left: 8em;
    color: white;
    height: 20em;
    width: 30em;
    display: flex;
    flex-direction: column;
  }
  
  .tituloDivEstaticoDerecha {
    flex: 0.5;
    text-align: left;
    font-size: 1.25em
  }
  
  .descripcionDivEstaticoDerecha {
    flex: 1;
    display: flex;
    text-align: left;
    font-size: 1.25em;
    align-items: center;
  }
  
  .expandirDivEstaticoDerecha {
    flex: 0.5;
    display: flex;
    font-size: 1.15em;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  
  .expandirFotoReduccion {
    display: flex;
    width: 175px;
    align-items: center;
    color: white
  }
  
  .tituloBotonExpandirFotoReduccion {
    font-size: 1.15em;
    font-weight: bold;
    text-align: left;
    margin-right: 20px
  }
  
  .iconoBotonExpandirFotoReduccion {
    position: relative;
    width: 1.15em;
    height: 1.15em;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1em;
    cursor: pointer
  }
}

@media (min-width: 1560px) {
  @keyframes entradaDesdeDerecha {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
  
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .estiloDivPrincipalFotoReduccion {
    width: 100%;
    height: 200vh;
    position: relative;
  
    margin-bottom: 50px;

  }
  
  .estiloDivStickyFotoReduccion {
    display: block;
    top: 0;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    opacity: 1;
    z-index: 2;
    height: 100vh;
  }
  
  .estiloDivEstaticoFotoReduccion {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    bottom: 0;
    opacity: 1;
    z-index: 1;
  }
  
  .contenedorDivEstatico {
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    max-width: 2000px;
    height: 100%;
  }
  
  .divEstaticoIzquierda {
    position: relative;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }
  
  .imagenDivEstaticoIzquierda {
    display: flex;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    height: 80%
  }
  
  .tituloDivEstaticoIzquierda {
    display: flex;
    height: 20%;
    width: 100%;
    text-align: right;
    align-items: center;
    font-size: 2.35em;
    color: white
  }
  
  
  .divEstaticoDerecha {
    display: flex;
    flex: 1
  }
  
  .contenedorDivEstaticoDerecha {
    width: 100%;
    display: flex;
    transition: opacity 1s ease-out, transform 0.5s ease-out;
    justify-content: unset;
    align-items: unset;
  }
  
  .contenedortituloDescripcionDivEstaticoDerecha {
    position: relative;
    top: 13em;
    left: 12em;
    color: white;
    height: 20em;
    width: 30em;
    display: flex;
    flex-direction: column;
  }
  
  .tituloDivEstaticoDerecha {
    flex: 0.5;
    text-align: left;
    font-size: 1.5em
  }
  
  .descripcionDivEstaticoDerecha {
    flex: 1;
    display: flex;
    text-align: left;
    font-size: 1.25em;
    align-items: center;
  }
  
  .expandirDivEstaticoDerecha {
    flex: 0.5;
    display: flex;
    font-size: 1.15em;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  
  .expandirFotoReduccion {
    display: flex;
    width: 175px;
    align-items: center;
    color: white
  }
  
  .tituloBotonExpandirFotoReduccion {
    font-size: 1.15em;
    font-weight: bold;
    text-align: left;
    margin-right: 20px
  }
  
  .iconoBotonExpandirFotoReduccion {
    position: relative;
    width: 1.15em;
    height: 1.15em;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1em;
    cursor: pointer
  }
}

/*

@media only screen and (min-width: 2500px) {
  .estiloDivPrincipalFotoReduccion {
    height: 37.5vh;
  }

  .estiloDivStickyFotoReduccion {
    display: none;
  }

  .estiloDivEstaticoFotoReduccion {
    height: 37.5vh;
  }

  .tituloDivEstaticoIzquierda {
    font-size: 1vw;
  }

  .tituloDivEstaticoDerecha {
    font-size: 0.7vw
  }

  .descripcionDivEstaticoDerecha {
    font-size: 0.4vw;
  }

  .expandirDivEstaticoDerecha {
    font-size: 0.5vw;
  }

  .tituloBotonExpandirFotoReduccion {
    font-size: 0.5vw;
  }

  .iconoBotonExpandirFotoReduccion {
    width: 0.5vw;
    height: 0.5vw;
    font-size: 0.5vw;
  }
}

@media only screen and 
(min-width: 600px) and
(max-width: 2499px) and
(min-height: 600px) and
(max-height: 2499px){
  @keyframes entradaDesdeDerecha {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
  
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .estiloDivPrincipalFotoReduccion {
    width: 100%;
    height: 200vh;
    position: relative;
  
    margin-bottom: 50px;
  }
  
  .estiloDivStickyFotoReduccion {
    display: block;
    top: 0;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    opacity: 1;
    z-index: 2;
  }
  
  .estiloDivEstaticoFotoReduccion {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    bottom: 0;
    opacity: 1;
    z-index: 1;
  }
  
  .contenedorDivEstatico {
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    max-width: 2000px;
    height: 100%;
  }
  
  .divEstaticoIzquierda {
    position: relative;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }
  
  .imagenDivEstaticoIzquierda {
    display: flex;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    height: 80%
  }
  
  .tituloDivEstaticoIzquierda {
    display: flex;
    height: 20%;
    width: 100%;
    text-align: right;
    align-items: center;
    font-size: 2.5vw;
    color: white
  }
  
  
  .divEstaticoDerecha {
    display: flex;
    flex: 1
  }
  
  .contenedorDivEstaticoDerecha {
    width: 100%;
    display: flex;
    transition: opacity 1s ease-out, transform 0.5s ease-out;
  }
  
  .contenedortituloDescripcionDivEstaticoDerecha {
    position: relative;
    top: 35%;
    left: 35%;
    color: white;
    height: 40%;
    width: 60%;
    display: flex;
    flex-direction: column;
  }
  
  .tituloDivEstaticoDerecha {
    flex: 0.5;
    text-align: left;
    font-size: 1.5vw
  }
  
  .descripcionDivEstaticoDerecha {
    flex: 1;
    display: flex;
    text-align: left;
    font-size: 1vw;
    align-items: center;
  }
  
  .expandirDivEstaticoDerecha {
    flex: 0.5;
    display: flex;
    font-size: 1vw;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  
  .expandirFotoReduccion {
    display: flex;
    width: 175px;
    align-items: center;
    color: white
  }
  
  .tituloBotonExpandirFotoReduccion {
    font-size: 1vw;
    font-weight: bold;
    text-align: left;
    margin-right: 20px
  }
  
  .iconoBotonExpandirFotoReduccion {
    position: relative;
    width: 1vw;
    height: 1vw;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 0.8vw;
    cursor: pointer
  }
}

@media only screen and 
(min-width: 760px) and
(max-width: 1370px) and
(min-height: 760px) and
(max-height: 1370px) {
  @keyframes entradaDesdeDerecha {
    from {
      transform: translateX(100%);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .estiloDivPrincipalFotoReduccion {
    width: 100%;
    height: 200vh;
    position: relative;

    margin-bottom: 50px;
    background-color: blue;
  }

  .estiloDivStickyFotoReduccion {
    display: block;
    top: 0;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    opacity: 1;
    z-index: 2;
  }

  .estiloDivEstaticoFotoReduccion {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    bottom: 0;
    opacity: 1;
    z-index: 1;
  }

  .contenedorDivEstatico {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    max-width: 2000px;
    height: 100%;
  }

  .divEstaticoIzquierda {
    position: relative;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }

  .imagenDivEstaticoIzquierda {
    display: flex;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    height: 80%
  }

  .tituloDivEstaticoIzquierda {
    display: flex;
    height: 20%;
    width: 100%;
    text-align: right;
    align-items: center;
    font-size: 2.5vw;
    color: white
  }


  .divEstaticoDerecha {
    display: flex;
    flex: 1
  }

  .contenedorDivEstaticoDerecha {
    width: 100%;
    display: flex;
    transition: opacity 1s ease-out, transform 0.5s ease-out;
  }

  .contenedortituloDescripcionDivEstaticoDerecha {
    position: relative;
    top: 15%;
    left: 35%;
    color: white;
    height: 40%;
    width: 55%;
    display: flex;
    flex-direction: column;
  }

  .tituloDivEstaticoDerecha {
    flex: 0.5;
    text-align: left;
    font-size: 2vw
  }

  .descripcionDivEstaticoDerecha {
    flex: 1;
    display: flex;
    text-align: left;
    font-size: 2vw;
    align-items: center;
  }

  .expandirDivEstaticoDerecha {
    flex: 0.5;
    display: flex;
    font-size: 2vw;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }

  .expandirFotoReduccion {
    display: flex;
    width: 175px;
    align-items: center;
    color: white
  }

  .tituloBotonExpandirFotoReduccion {
    font-size: 2vw;
    font-weight: bold;
    text-align: left;
    margin-right: 20px
  }

  .iconoBotonExpandirFotoReduccion {
    position: relative;
    width: 2vw;
    height: 2vw;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 0.8vw;
    cursor: pointer
  }
}

@media only screen and (min-width: 540px) and (max-width: 1200px) and (min-height: 935px) and (max-height: 1200px) {
  .estiloDivPrincipalFotoReduccion {
    width: 100%;
    height: 200vh;
    position: relative;

    margin-bottom: 50px;
    background-color: red;
  }

  .estiloDivStickyFotoReduccion {
    display: block;
    top: 0;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    opacity: 1;
    z-index: 2;
  }

  .estiloDivEstaticoFotoReduccion {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    bottom: 0;
    opacity: 1;
    z-index: 1;
  }

  .contenedorDivEstatico {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    max-width: 2000px;
    height: 100%;
  }

  .divEstaticoIzquierda {
    position: relative;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }

  .imagenDivEstaticoIzquierda {
    display: flex;
    background: url("https://images.pexels.com/photos/6804069/pexels-photo-6804069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") right bottom;
    background-size: cover;
    height: 80%
  }

  .tituloDivEstaticoIzquierda {
    display: flex;
    height: 20%;
    width: 100%;
    text-align: right;
    align-items: center;
    font-size: 3vw;
    color: white
  }


  .divEstaticoDerecha {
    display: flex;
    flex: 1
  }

  .contenedorDivEstaticoDerecha {
    width: 100%;
    display: flex;
    transition: opacity 1s ease-out, transform 0.5s ease-out;
  }

  .contenedortituloDescripcionDivEstaticoDerecha {
    position: relative;
    top: 15%;
    left: 20%;
    color: white;
    height: 40%;
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  .tituloDivEstaticoDerecha {
    flex: 0.5;
    text-align: left;
    font-size: 3vw
  }

  .descripcionDivEstaticoDerecha {
    flex: 1;
    display: flex;
    text-align: left;
    font-size: 2.5vw;
    align-items: center;
  }

  .expandirDivEstaticoDerecha {
    flex: 0.5;
    display: flex;
    font-size: 2vw;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }

  .expandirFotoReduccion {
    display: flex;
    width: 175px;
    align-items: center;
    color: white
  }

  .tituloBotonExpandirFotoReduccion {
    font-size: 2.5vw;
    font-weight: bold;
    text-align: left;
    margin-right: 20px
  }

  .iconoBotonExpandirFotoReduccion {
    position: relative;
    width: 2.5vw;
    height: 2.5vw;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 2.5vw;
    cursor: pointer
  }
}
*/