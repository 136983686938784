/*.ComponenteCarruselDoble {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tituloCarruselDoble {
    max-width: 2000px;
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 20px;
}

.contenedorTituloSubtitulo {
    width: 50%;
    color: white;
}

.titulo {
    font-size: 5vw;
    margin: 0px
}

.subtitulo {
    margin: 0px;
    font-size: 2vw
}


.contenedor {
    display: flex;
    max-width: 2000px;
    width: 100%;
    height: 75vh;
    overflow: hidden;
    margin-bottom: 20px;
}

.divIzquierdo {
    flex: 1;
    transition: transform 0.5s ease-in-out;
}

.divIzquierdo.salida {
    transform: translateX(-100%);
}

.divDerecho {
    flex: 1;
    box-sizing: border-box;
    transition: transform 0.5s ease-in-out;
    position: relative;
}

.divDerecho.salida {
    transform: translateX(100%);
}

.tituloDescripcionDerecho {
    position: absolute;
    bottom: 0;
    right: 5%;
    color: white;
    height: 80%;
    width: 70%;
    display: flex;
    flex-direction: column;
}

.tituloDerecho {
    flex: 0.5;
    text-align: left;
    font-size: 1.5vw
}

.descripcionDerecho {
    flex: 1;
    display: flex;
    text-align: left;
    font-size: 1.5vw;
    align-items: center
}

.expandirFotoCarusel {
    display: flex;
    width: 175px;
    align-items: center;
    color: white
}

.expandirCarrusel {
    font-size: 1vw;
    font-weight: bold;
    text-align: left;
    margin-right: 10px;
}

.iconoExpandir {

    position: relative;
    width: 1vw;
    height: 1vw;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 0.8vw;
    cursor: pointer
}

.controlador {
    max-width: 2000px;
    width: 100%;
    position: relative
}

.nuevoContenedor {
    display: flex;
    width: 10%;
    height: 3vw;
    margin-left: 3vw;
    margin-bottom: 1.5vw;
}

.iconoIzquierdo,
.iconoDerecho {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 2vw;
    cursor: pointer;
    color: white;
    transition: background-color 0.5s;
}

.iconoIzquierdo:hover,
.iconoDerecho:hover {
    background-color: grey;
}

.filaDeDivs {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 7.5vw;
    height: 2vw;
}

.divEnFila {
    background-color: grey;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    width: 0.85vw;
    height: 0.85vw
}

.divEnFila:hover {
    background-color: white;
}

.divEnFila.resaltado {
    background-color: #cce5ff;
}
@media only screen and (min-width: 760px) and (max-width: 1370px) and (min-height: 760px) and (max-height: 1370px) {
    .ComponenteCarruselDoble {
        background-color: blue;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tituloCarruselDoble {
        max-width: 2000px;
        width: 100%;
        height: 25vw;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .contenedorTituloSubtitulo {
        width: 50%;
        color: white;
    }

    .titulo {
        font-size: 5vw;
        margin: 0px
    }

    .subtitulo {
        margin: 0px;
        font-size: 2vw
    }


    .contenedor {
        display: flex;
        max-width: 2000px;
        width: 100%;
        height: 35vh;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .divIzquierdo {
        flex: 1;
        transition: transform 0.5s ease-in-out;
    }

    .divIzquierdo.salida {
        transform: translateX(-100%);
    }

    .divDerecho {
        flex: 1;
        box-sizing: border-box;
        transition: transform 0.5s ease-in-out;
        position: relative;
    }

    .divDerecho.salida {
        transform: translateX(100%);
    }

    .tituloDescripcionDerecho {
        position: absolute;
        bottom: 0;
        right: 5%;
        color: white;
        height: 80%;
        width: 70%;
        display: flex;
        flex-direction: column;
    }

    .tituloDerecho {
        flex: 0.5;
        text-align: left;
        font-size: 1.5vw
    }

    .descripcionDerecho {
        flex: 1;
        display: flex;
        text-align: left;
        font-size: 1.5vw;
        align-items: center
    }

    .expandirFotoCarusel {
        display: flex;
        width: 175px;
        align-items: center;
        color: white
    }

    .expandirCarrusel {
        font-size: 1vw;
        font-weight: bold;
        text-align: left;
        margin-right: 10px;
    }

    .iconoExpandir {

        position: relative;
        width: 1vw;
        height: 1vw;
        background-color: #01ccc8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        font-size: 0.8vw;
        cursor: pointer
    }

    .controlador {
        max-width: 2000px;
        width: 100%;
        position: relative
    }

    .nuevoContenedor {
        display: flex;
        width: 10%;
        height: 3vw;
        margin-left: 3vw;
        margin-bottom: 1.5vw;
    }

    .iconoIzquierdo,
    .iconoDerecho {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 2vw;
        cursor: pointer;
        color: white;
        transition: background-color 0.5s;
    }

    .iconoIzquierdo:hover,
    .iconoDerecho:hover {
        background-color: grey;
    }

    .filaDeDivs {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 5%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 7.5vw;
        height: 2vw;
    }

    .divEnFila {
        background-color: grey;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        width: 0.85vw;
        height: 0.85vw
    }

    .divEnFila:hover {
        background-color: white;
    }

    .divEnFila.resaltado {
        background-color: #cce5ff;
    }
}

@media only screen and (min-width: 540px) and (max-width: 1200px) and (min-height: 935px) and (max-height: 1200px) {
    .ComponenteCarruselDoble {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: red;
    }

    .tituloCarruselDoble {
        max-width: 2000px;
        width: 100%;
        height: 25vh;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .contenedorTituloSubtitulo {
        width: 75%;
        color: white;
    }

    .titulo {
        font-size: 7vw;
        margin: 0px
    }

    .subtitulo {
        margin: 0px;
        font-size: 3vw
    }

    .contenedor {
        display: flex;
        flex-direction: column;
        max-width: 2000px;
        width: 100%;
        height: 90vh;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .divIzquierdo {
        flex: 1;
        transition: transform 0.5s ease-in-out;
    }

    .divIzquierdo.salida {
        transform: translateX(-100%);
    }

    .divDerecho {
        flex: 1;
        box-sizing: border-box;
        transition: transform 0.5s ease-in-out;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .divDerecho.salida {
        transform: translateX(100%);
    }

    .tituloDescripcionDerecho {
        position: absolute;
        color: white;
        height: 75%;
        width: 75%;
        display: flex;
        flex-direction: column;
    }

    .tituloDerecho {
        flex: 0.5;
        text-align: left;
        font-size: 3vw
    }

    .descripcionDerecho {
        flex: 1;
        display: flex;
        text-align: left;
        font-size: 3vw;
        align-items: center
    }

    .expandirFotoCarusel {
        display: flex;
        width: 175px;
        align-items: center;
        color: white
    }

    .expandirCarrusel {
        font-size: 3.5vw;
        font-weight: bold;
        text-align: left;
        margin-right: 10px;
    }

    .iconoExpandir {

        position: relative;
        width: 3.5vw;
        height: 3.5vw;
        background-color: #01ccc8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        font-size: 3vw;
        cursor: pointer
    }

    .controlador {
        max-width: 2000px;
        width: 100%;
        position: relative
    }

    .nuevoContenedor {
        display: flex;
        width: 25%;
        height: 10vw;
        margin-left: 3vw;
        margin-bottom: 1.5vw;
    }

    .iconoIzquierdo,
    .iconoDerecho {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 4vw;
        cursor: pointer;
        color: white;
        transition: background-color 0.5s;
    }

    .iconoIzquierdo:hover,
    .iconoDerecho:hover {
        background-color: grey;
    }

    .filaDeDivs {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 5%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 20vw;
        height: 7vw;
    }

    .divEnFila {
        background-color: grey;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        width: 2.5vw;
        height: 2.5vw
    }

    .divEnFila:hover {
        background-color: white;
    }

    .divEnFila.resaltado {
        background-color: #cce5ff;
    }
}

@media only screen and (min-width: 2500px) {
    .titulo {
        font-size: 3vw;
        margin: 0px
    }

    .subtitulo {
        margin: 0px;
        font-size: 0.8vw
    }

    .contenedor {
        height: 12.5vw;
    }

    .tituloDerecho {
        font-size: 0.7vw
    }

    .descripcionDerecho {
        font-size: 0.4vw;
    }

    .expandirCarrusel {
        font-size: 0.5vw;
    }

    .iconoExpandir {
        width: 0.5vw;
        height: 0.5vw;
        font-size: 0.5vw;
    }


    .nuevoContenedor {
        display: flex;
        height: 1.8vw;
        margin-left: 1.8vw;
        margin-bottom: 0.8vw;
    }

    .iconoIzquierdo,
    .iconoDerecho {
        font-size: 1vw;
    }

    .filaDeDivs {
        width: 6vw;
        height: 2vw;
    }

    .divEnFila {
        width: 0.65vw;
        height: 0.65vw
    }
}
*/

.ComponenteCarruselDoble {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tituloCarruselDoble {
    max-width: 2000px;
    width: 100%;
    height: 12.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 20px;
}
.contenedorTituloSubtitulo {
    width: 75%;
    color: white;
}
.titulo {
    font-size: 2.5em;
    margin: 0px
}
.subtitulo {
    margin: 0px;
    font-size: 1em
}
.contenedor {
    display: flex;
    flex-direction: column;
    max-width: 2000px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
}
.divIzquierdo {
    transition: transform 0.5s ease-in-out;
    display: flex;
    align-items: center;
    height: 15em;
}
.imagenDivIzquierdo{
    width: 100%
}
.divIzquierdo.salida {
    transform: translateX(-100%);
}
.divDerecho {
    height: 25em;
    box-sizing: border-box;
    transition: transform 0.5s ease-in-out;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.divDerecho.salida {
    transform: translateX(100%);
    /* Movemos el divDerecho hacia la derecha para salir */
}
.tituloDescripcionDerecho {
    position: absolute;
    color: white;
    height: 75%;
    width: 75%;
    display: flex;
    flex-direction: column;
}
.tituloDerecho {
    flex: 0.5;
    text-align: left;
    font-size: 1em
}
.descripcionDerecho {
    flex: 1;
    display: flex;
    text-align: left;
    font-size: 1em;
    align-items: center
}
.expandirFotoCarusel {
    display: flex;
    width: 175px;
    align-items: center;
    color: white
}
.expandirCarrusel {
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    margin-right: 10px;
}
.iconoExpandir {

    position: relative;
    width: 1em;
    height: 1em;
    background-color: #01ccc8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer
}
.controlador {
    max-width: 2000px;
    width: 100%;
    position: relative
}
.nuevoContenedor {
    display: flex;
    width: 25%;
    height: 3em;
    margin-left: 2em;

}
.iconoIzquierdo,
.iconoDerecho {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 4vw;
    cursor: pointer;
    color: white;
    transition: background-color 0.5s;
}
.iconoIzquierdo:hover,
.iconoDerecho:hover {
    background-color: grey;
}
.filaDeDivs {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 5em;
    height: 3em;
}
.divEnFila {
    background-color: grey;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    /* Agregamos una transición para el color de fondo */
    width: 0.75em;
    height: 0.75em
}
.divEnFila:hover {
    background-color: white;
    /* Cambia el color de fondo al pasar el ratón */
}
.divEnFila.resaltado {
    background-color: #cce5ff;
    /* Color de fondo para el resaltado */
}

@media (min-width: 475px){
    .ComponenteCarruselDoble {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tituloCarruselDoble {
        max-width: 2000px;
        width: 100%;
        height: 12.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .contenedorTituloSubtitulo {
        width: 75%;
        color: white;
    }
    .titulo {
        font-size: 2.5em;
        margin: 0px
    }
    .subtitulo {
        margin: 0px;
        font-size: 1em
    }
    .contenedor {
        display: flex;
        flex-direction: column;
        max-width: 2000px;
        width: 100%;
        overflow: hidden;
        margin-bottom: 20px;
        
    }
    .divIzquierdo {
        transition: transform 0.5s ease-in-out;
        display: flex;
        align-items: center;
        height: 20em;
        overflow: hidden;
    }
    .imagenDivIzquierdo{
        width: 100%
    }
    .divIzquierdo.salida {
        transform: translateX(-100%);
    }
    .divDerecho {
        box-sizing: border-box;
        transition: transform 0.5s ease-in-out;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25em;
   
    }
    .divDerecho.salida {
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .tituloDescripcionDerecho {
        position: absolute;
        color: white;
        height: 75%;
        width: 75%;
        display: flex;
        flex-direction: column;
    }
    .tituloDerecho {
        flex: 0.5;
        text-align: left;
        font-size: 1em
    }
    .descripcionDerecho {
        flex: 1;
        display: flex;
        text-align: left;
        font-size: 1em;
        align-items: center
    }
    .expandirFotoCarusel {
        display: flex;
        width: 175px;
        align-items: center;
        color: white
    }
    .expandirCarrusel {
        font-size: 1em;
        font-weight: bold;
        text-align: left;
        margin-right: 10px;
    }
    .iconoExpandir {
        position: relative;
        width: 1em;
        height: 1em;
        background-color: #01ccc8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer
    }

    .controlador {
        max-width: 2000px;
        width: 100%;
        position: relative
    }
    .nuevoContenedor {
        display: flex;
        width: 25%;
        height: 3em;
        margin-left: 2em;
    
    }
    .iconoIzquierdo,
    .iconoDerecho {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 4vw;
        cursor: pointer;
        color: white;
        transition: background-color 0.5s;
    }
    .iconoIzquierdo:hover,
    .iconoDerecho:hover {
        background-color: grey;
    }
    .filaDeDivs {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 5%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 5em;
        height: 3em;
    }
    .divEnFila {
        background-color: grey;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        /* Agregamos una transición para el color de fondo */
        width: 0.75em;
        height: 0.75em
    }
    .divEnFila:hover {
        background-color: white;
        /* Cambia el color de fondo al pasar el ratón */
    }
    .divEnFila.resaltado {
        background-color: #cce5ff;
        /* Color de fondo para el resaltado */
    }
}
@media (min-width: 640px){
    .ComponenteCarruselDoble {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tituloCarruselDoble {
        max-width: 2000px;
        width: 100%;
        height: 17.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .contenedorTituloSubtitulo {
        width: 75%;
        color: white;
    }
    .titulo {
        font-size: 3em;
        margin: 0px
    }
    .subtitulo {
        margin: 0px;
        font-size: 1.25em
    }
    .contenedor {
        display: flex;
        flex-direction: column;
        max-width: 2000px;
        width: 100%;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .divIzquierdo {
       display: flex;
       align-items: center;
        transition: transform 0.5s ease-in-out;
        height: 20em;
        overflow: hidden;
    }
    .imagenDivIzquierdo{
        width:100%
    }
    .divIzquierdo.salida {
        transform: translateX(-100%);
    }
    .divDerecho {
        box-sizing: border-box;
        transition: transform 0.5s ease-in-out;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25em;
    }
    .divDerecho.salida {
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .tituloDescripcionDerecho {
        position: absolute;
        color: white;
        height: 75%;
        width: 75%;
        display: flex;
        flex-direction: column;
    }
    .tituloDerecho {
        flex: 0.5;
        text-align: left;
        font-size: 1.25em
    }
    .descripcionDerecho {
        flex: 1;
        display: flex;
        text-align: left;
        font-size: 1.25em;
        align-items: center
    }
    .expandirFotoCarusel {
        display: flex;
        width: 175px;
        align-items: center;
        color: white
    }
    .expandirCarrusel {
        font-size: 1em;
        font-weight: bold;
        text-align: left;
        margin-right: 10px;
    }
    .iconoExpandir {
    
        position: relative;
        width: 1em;
        height: 1em;
        background-color: #01ccc8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer
    }
    .controlador {
        max-width: 2000px;
        width: 100%;
        position: relative
    }
    .nuevoContenedor {
        display: flex;
        width: 25%;
        height: 3em;
        margin-left: 2em;
    
    }
    .iconoIzquierdo,
    .iconoDerecho {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 4vw;
        cursor: pointer;
        color: white;
        transition: background-color 0.5s;
    }
    .iconoIzquierdo:hover,
    .iconoDerecho:hover {
        background-color: grey;
    }
    .filaDeDivs {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 5%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 5em;
        height: 3em;
    }
    .divEnFila {
        background-color: grey;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        /* Agregamos una transición para el color de fondo */
        width: 0.75em;
        height: 0.75em
    }
    .divEnFila:hover {
        background-color: white;
        /* Cambia el color de fondo al pasar el ratón */
    }
    .divEnFila.resaltado {
        background-color: #cce5ff;
        /* Color de fondo para el resaltado */
    }
}
@media (min-width: 768px){
    .ComponenteCarruselDoble {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tituloCarruselDoble {
        max-width: 2000px;
        width: 100%;
        height: 17.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .contenedorTituloSubtitulo {
        width: 75%;
        color: white;
    }
    .titulo {
        font-size: 3em;
        margin: 0px
    }
    .subtitulo {
        margin: 0px;
        font-size: 1.25em
    }
    .contenedor {
        display: flex;
        flex-direction: column;
        max-width: 2000px;
        width: 100%;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .divIzquierdo {
        transition: transform 0.5s ease-in-out;
        display: flex;
        align-items: center;
        overflow: hidden;
        height: 25em;
    }
    .imagenDivIzquierdo{
        width:100%
    }
    .divIzquierdo.salida {
        transform: translateX(-100%);
    }
    .divDerecho {
        box-sizing: border-box;
        transition: transform 0.5s ease-in-out;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25em;
    }
    .divDerecho.salida {
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .tituloDescripcionDerecho {
        position: absolute;
        color: white;
        height: 75%;
        width: 75%;
        display: flex;
        flex-direction: column;
    }
    .tituloDerecho {
        flex: 0.5;
        text-align: left;
        font-size: 1.25em
    }
    .descripcionDerecho {
        flex: 1;
        display: flex;
        text-align: left;
        font-size: 1.25em;
        align-items: center
    }
    .expandirFotoCarusel {
        display: flex;
        width: 175px;
        align-items: center;
        color: white
    }
    .expandirCarrusel {
        font-size: 1em;
        font-weight: bold;
        text-align: left;
        margin-right: 10px;
    }
    .iconoExpandir {
    
        position: relative;
        width: 1em;
        height: 1em;
        background-color: #01ccc8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer
    }
    .controlador {
        max-width: 2000px;
        width: 100%;
        position: relative
    }
    .nuevoContenedor {
        display: flex;
        width: 25%;
        height: 3em;
        margin-left: 2em;
    
    }
    .iconoIzquierdo,
    .iconoDerecho {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 4vw;
        cursor: pointer;
        color: white;
        transition: background-color 0.5s;
    }
    .iconoIzquierdo:hover,
    .iconoDerecho:hover {
        background-color: grey;
    }
    .filaDeDivs {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 5%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 7em;
        height: 3em;
    }
    .divEnFila {
        background-color: grey;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        /* Agregamos una transición para el color de fondo */
        width: 0.75em;
        height: 0.75em
    }
    .divEnFila:hover {
        background-color: white;
        /* Cambia el color de fondo al pasar el ratón */
    }
    .divEnFila.resaltado {
        background-color: #cce5ff;
        /* Color de fondo para el resaltado */
    }
}
@media (min-width: 1024px){
    .ComponenteCarruselDoble {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tituloCarruselDoble {
        max-width: 2000px;
        width: 100%;
        height: 17.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .contenedorTituloSubtitulo {
        width: 75%;
        color: white;
    }
    .titulo {
        font-size: 3.5em;
        margin: 0px
    }
    .subtitulo {
        margin: 0px;
        font-size: 1.5em
    }
    .contenedor {
        display: flex;
        flex-direction: row;
        max-width: 2000px;
        width: 100%;

        overflow: hidden;
        margin-bottom: 20px;
    }
    .divIzquierdo {
        flex: 1.5;
        position: relative;
        transition: transform 0.5s ease-in-out;
        display: flex;
        justify-content: center;
        height: 40em;
    }
    .imagenDivIzquierdo{
        position:absolute;
        height:100%;
        width: unset;
        left:-150px;
        top:0;
    }
    .divIzquierdo.salida {
        transform: translateX(-100%);
    }
    .divDerecho {
        flex: 1;
        box-sizing: border-box;
        transition: transform 0.5s ease-in-out;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40em;
    }
    .divDerecho.salida {
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .tituloDescripcionDerecho {
        position: absolute;
        color: white;
        height: 75%;
        width: 75%;
        display: flex;
        flex-direction: column;
    }
    .tituloDerecho {
        flex: 0.5;
        text-align: left;
        font-size: 1.25em
    }
    .descripcionDerecho {
        flex: 1;
        display: flex;
        text-align: left;
        font-size: 1.25em;
        align-items: center
    }
    .expandirFotoCarusel {
        display: flex;
        width: 175px;
        align-items: center;
        color: white
    }
    .expandirCarrusel {
        font-size: 1em;
        font-weight: bold;
        text-align: left;
        margin-right: 10px;
    }
    .iconoExpandir {
    
        position: relative;
        width: 1em;
        height: 1em;
        background-color: #01ccc8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer
    }
    .controlador {
        max-width: 2000px;
        width: 100%;
        position: relative
    }
    .nuevoContenedor {
        display: flex;
        width: 25%;
        height: 3em;
        margin-left: 2em;
    
    }
    .iconoIzquierdo,
    .iconoDerecho {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 4vw;
        cursor: pointer;
        color: white;
        transition: background-color 0.5s;
    }
    .iconoIzquierdo:hover,
    .iconoDerecho:hover {
        background-color: grey;
    }
    .filaDeDivs {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 5%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 7em;
        height: 3em;
    }
    .divEnFila {
        background-color: grey;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        /* Agregamos una transición para el color de fondo */
        width: 0.75em;
        height: 0.75em
    }
    .divEnFila:hover {
        background-color: white;
        /* Cambia el color de fondo al pasar el ratón */
    }
    .divEnFila.resaltado {
        background-color: #cce5ff;
        /* Color de fondo para el resaltado */
    }
}
@media (min-width: 1280px){
    .ComponenteCarruselDoble {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tituloCarruselDoble {
        max-width: 2000px;
        width: 100%;
        height: 17.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .contenedorTituloSubtitulo {
        width: 75%;
        color: white;
    }
    .titulo {
        font-size: 4.5em;
        margin: 0px
    }
    .subtitulo {
        margin: 0px;
        font-size: 1.5em
    }
    .contenedor {
        display: flex;
        flex-direction: row;
        max-width: 2000px;
        width: 100%;
        height: 35em;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .divIzquierdo {
        flex: 1.5;
        position: relative;
        transition: transform 0.5s ease-in-out;
        display: flex;
        justify-content: center;
        height: 40em;
    }
    .imagenDivIzquierdo{
        position:absolute;
        height:100%;
        width: unset;
        left:-150px;
        top:0;
    }
    .divIzquierdo.salida {
        transform: translateX(-100%);
    }
    .divDerecho {
        flex: 1;
        box-sizing: border-box;
        transition: transform 0.5s ease-in-out;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40em;
    }
    .divDerecho.salida {
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .tituloDescripcionDerecho {
        position: absolute;
        color: white;
        height: 75%;
        width: 75%;
        display: flex;
        flex-direction: column;
    }
    .tituloDerecho {
        flex: 0.5;
        text-align: left;
        font-size: 1.25em
    }
    .descripcionDerecho {
        flex: 1;
        display: flex;
        text-align: left;
        font-size: 1.25em;
        align-items: center
    }
    .expandirFotoCarusel {
        display: flex;
        width: 175px;
        align-items: center;
        color: white
    }
    .expandirCarrusel {
        font-size: 1em;
        font-weight: bold;
        text-align: left;
        margin-right: 10px;
    }
    .iconoExpandir {
    
        position: relative;
        width: 1em;
        height: 1em;
        background-color: #01ccc8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer
    }
    .controlador {
        max-width: 2000px;
        width: 100%;
        position: relative
    }
    .nuevoContenedor {
        display: flex;
        width: 20%;
        height: 3em;
        margin-left: 2em;
    
    }
    .iconoIzquierdo,
    .iconoDerecho {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 2em;
        cursor: pointer;
        color: white;
        transition: background-color 0.5s;
    }
    .iconoIzquierdo:hover,
    .iconoDerecho:hover {
        background-color: grey;
    }
    .filaDeDivs {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 5%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 7em;
        height: 3em;
    }
    .divEnFila {
        background-color: grey;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        /* Agregamos una transición para el color de fondo */
        width: 0.75em;
        height: 0.75em
    }
    .divEnFila:hover {
        background-color: white;
        /* Cambia el color de fondo al pasar el ratón */
    }
    .divEnFila.resaltado {
        background-color: #cce5ff;
        /* Color de fondo para el resaltado */
    }
}
@media (min-width: 1560px){
    .ComponenteCarruselDoble {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tituloCarruselDoble {
        max-width: 2000px;
        width: 100%;
        height: 17.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .contenedorTituloSubtitulo {
        width: 75%;
        color: white;
    }
    .titulo {
        font-size: 4.5em;
        margin: 0px
    }
    .subtitulo {
        margin: 0px;
        font-size: 1.5em
    }
    .contenedor {
        display: flex;
        flex-direction: row;
        max-width: 1300px;
        width: 100%;
        height: 35em;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .divIzquierdo {
        flex: 1.5;
        position: relative;
        transition: transform 0.5s ease-in-out;
        display: flex;
        justify-content: center;
        height: 40em;
    }
    .imagenDivIzquierdo{
        position:absolute;
        height:100%;
        width: unset;
        left:-150px;
        top:0;
    }
    .divIzquierdo.salida {
        transform: translateX(-100%);
    }
    .divDerecho {
        flex: 1;
        box-sizing: border-box;
        transition: transform 0.5s ease-in-out;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40em;
    }
    .divDerecho.salida {
        transform: translateX(100%);
        /* Movemos el divDerecho hacia la derecha para salir */
    }
    .tituloDescripcionDerecho {
        position: absolute;
        color: white;
        height: 75%;
        width: 75%;
        display: flex;
        flex-direction: column;
    }
    .tituloDerecho {
        flex: 0.5;
        text-align: left;
        font-size: 1.25em
    }
    .descripcionDerecho {
        flex: 1;
        display: flex;
        text-align: left;
        font-size: 1.25em;
        align-items: center
    }
    .expandirFotoCarusel {
        display: flex;
        width: 175px;
        align-items: center;
        color: white
    }
    .expandirCarrusel {
        font-size: 1em;
        font-weight: bold;
        text-align: left;
        margin-right: 10px;
    }
    .iconoExpandir {
    
        position: relative;
        width: 1em;
        height: 1em;
        background-color: #01ccc8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer
    }
    .controlador {
        max-width: 2000px;
        width: 100%;
        position: relative
    }
    .nuevoContenedor {
        display: flex;
        width: 15%;
        height: 3em;
        margin-left: 4em;
    
    }
    .iconoIzquierdo,
    .iconoDerecho {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 2em;
        cursor: pointer;
        color: white;
        transition: background-color 0.5s;
    }
    .iconoIzquierdo:hover,
    .iconoDerecho:hover {
        background-color: grey;
    }
    .filaDeDivs {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 5%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 7em;
        height: 3em;
    }
    .divEnFila {
        background-color: grey;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        /* Agregamos una transición para el color de fondo */
        width: 0.75em;
        height: 0.75em
    }
    .divEnFila:hover {
        background-color: white;
        /* Cambia el color de fondo al pasar el ratón */
    }
    .divEnFila.resaltado {
        background-color: #cce5ff;
        /* Color de fondo para el resaltado */
    }
}

