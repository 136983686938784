.estiloDivPrincipal {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 350vh;
    position: relative;
}
.estiloDivSticky {
    width: 100%;
    max-width: 2000px;
    height: 100vh;
    top: 0;
    opacity: 1;
    z-index: 2;
    background-color: black;
}
.estiloDivEstatico {
    width: 100%;
    max-width: 2000px;
    height: 100vh;
    position: absolute;
    bottom: 0;
    opacity: 1;
    z-index: 1
}
.tituloSticky {
    display: flex;
    font-size: 0.85em;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    color: white;
    margin: 0px;
}
.cuadroScroll1 {
    position: absolute;
    top: 100vh;
    left: 5vw;
    width: 80vw;
    height: 45vh;
    background-color: #01ccc8;
    z-index: 3;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cuadroScroll2 {
    position: absolute;
    top: 150vh;
    left: 15vw;
    width: 80vw;
    height: 45vh;
    background-color: #03787f;
    z-index: 3;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cuadroScroll3 {
    position: absolute;
    top: 200vh;
    left: 5vw;
    width: 80vw;
    height: 45vh;
    background-color: #273782;
    z-index: 3;
    margin: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.frasePrincipalDescripcionScroll {

    position: relative;
    transition: top 0.5s ease-in-out;
    overflow: hidden;
    height: 100%;
    width: 75%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: white;

}
.frasePrincipalScroll {
    font-size: 5.5vw;
    font-weight: 500;
    margin-bottom: 7.5px
}
.descripcionScroll {
    font-size: 4.5vw
}
.fraseSecundariaDescripcionScroll {
    position: absolute;
    color: white;
    top: 100%;
    /* Ajusta el valor según tu diseño original */
    transition: top 0.5s ease-in-out;
    /* Modificado para animar el cambio en top */
    overflow: hidden;

    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: white;
}
.fraseSecundariaScroll{
    font-size: 5vw
}
.expandir {
    position: absolute;
    font-size: 5vw;
    color: white;
    top: 140%;
    /* Ajusta el valor según tu diseño original */
    right: 15%;
    transition: top 0.5s ease-in-out;
    /* Modificado para animar el cambio en top */
    overflow: hidden;
    cursor: pointer;
    font-weight: 500;
}

@media (min-width: 475px){
    .estiloDivPrincipal {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 350vh;
        position: relative;
    }
    .estiloDivSticky {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        top: 0;
        opacity: 1;
        z-index: 2;
        background-color: black;
    }
    .estiloDivEstatico {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        position: absolute;
        bottom: 0;
        opacity: 1;
        z-index: 1
    }
    .tituloSticky {
        display: flex;
        font-size: 1.25em;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
        color: white;
        margin: 0px;
    }
    .cuadroScroll1 {
        position: absolute;
        top: 100vh;
        left: 5vw;
        width: 70vw;
        height: 45vh;
        background-color: #01ccc8;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll2 {
        position: absolute;
        top: 150vh;
        left: 25vw;
        width: 70vw;
        height: 45vh;
        background-color: #03787f;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll3 {
        position: absolute;
        top: 200vh;
        left: 5vw;
        width: 70vw;
        height: 45vh;
        background-color: #273782;
        z-index: 3;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .frasePrincipalDescripcionScroll {
    
        position: relative;
        transition: top 0.5s ease-in-out;
        overflow: hidden;
        height: 100%;
        width: 75%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    
    }
    .frasePrincipalScroll {
        font-size: 5.5vw;
        font-weight: 500;
        margin-bottom: 7.5px
    }
    .descripcionScroll {
        font-size: 4.5vw
    }
    .fraseSecundariaDescripcionScroll {
        position: absolute;
        color: white;
     
        top: 100%;
        /* Ajusta el valor según tu diseño original */
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
    
        height: 100%;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    }
    .fraseSecundariaScroll{
        font-size: 4.5vw
    }
    .expandir {
        position: absolute;
        font-size: 4.5vw;
        color: white;
        top: 140%;
        /* Ajusta el valor según tu diseño original */
        right: 15%;
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
        cursor: pointer;
        font-weight: 500;
    }
}

@media (min-width: 640px){
    .estiloDivPrincipal {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 350vh;
        position: relative;
    }
    .estiloDivSticky {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        top: 0;
        opacity: 1;
        z-index: 2;
        background-color: black;
    }
    .estiloDivEstatico {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        position: absolute;
        bottom: 0;
        opacity: 1;
        z-index: 1
    }
    .tituloSticky {
        display: flex;
        font-size: 1.5em;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
        color: white;
        margin: 0px;
    }
    .cuadroScroll1 {
        position: absolute;
        top: 100vh;
        left: 15vw;
        width: 50vw;
        height: 45vh;
        background-color: #01ccc8;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll2 {
        position: absolute;
        top: 150vh;
        left: 35vw;
        width: 50vw;
        height: 45vh;
        background-color: #03787f;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll3 {
        position: absolute;
        top: 200vh;
        left: 15vw;
        width: 50vw;
        height: 45vh;
        background-color: #273782;
        z-index: 3;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .frasePrincipalDescripcionScroll {
    
        position: relative;
        transition: top 0.5s ease-in-out;
        overflow: hidden;
        height: 100%;
        width: 75%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    
    }
    .frasePrincipalScroll {
        font-size: 3vw;
        font-weight: 500;
        margin-bottom: 7.5px
    }
    .descripcionScroll {
        font-size: 2.5vw
    }
    .fraseSecundariaDescripcionScroll {
        position: absolute;
        color: white;
        font-size: 2vh;
        top: 100%;
        /* Ajusta el valor según tu diseño original */
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
    
        height: 100%;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    }
    .fraseSecundariaScroll{
        font-size: 2.5vw
    }
    .expandir {
        position: absolute;
        font-size: 2.5vw;
        color: white;
        top: 140%;
        /* Ajusta el valor según tu diseño original */
        right: 15%;
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
        cursor: pointer;
        font-weight: 500;
    }
}

@media (min-width: 768px){
    .estiloDivPrincipal {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 350vh;
        position: relative;
    }
    .estiloDivSticky {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        top: 0;
        opacity: 1;
        z-index: 2;
        background-color: black;
    }
    .estiloDivEstatico {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        position: absolute;
        bottom: 0;
        opacity: 1;
        z-index: 1
    }
    .tituloSticky {
        display: flex;
        font-size: 1.5em;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
        color: white;
        margin: 0px;
    }
    .cuadroScroll1 {
        position: absolute;
        top: 100vh;
        left: 15vw;
        width: 45vw;
        height: 45vh;
        background-color: #01ccc8;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll2 {
        position: absolute;
        top: 150vh;
        left: 45vw;
        width: 45vw;
        height: 45vh;
        background-color: #03787f;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll3 {
        position: absolute;
        top: 200vh;
        left: 15vw;
        width: 45vw;
        height: 45vh;
        background-color: #273782;
        z-index: 3;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .frasePrincipalDescripcionScroll {
    
        position: relative;
        transition: top 0.5s ease-in-out;
        overflow: hidden;
        height: 100%;
        width: 75%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    
    }
    .frasePrincipalScroll {
        font-size: 2.75vw;
        font-weight: 500;
        margin-bottom: 7.5px
    }
    .descripcionScroll {
        font-size: 2.25vw
    }
    .fraseSecundariaDescripcionScroll {
        position: absolute;
        color: white;
        top: 100%;
        /* Ajusta el valor según tu diseño original */
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
    
        height: 100%;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    }
    .fraseSecundariaScroll{
        font-size: 2.25vw
    }
    .expandir {
        position: absolute;
        font-size: 2.25vw;
        color: white;
        top: 140%;
        /* Ajusta el valor según tu diseño original */
        right: 15%;
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
        cursor: pointer;
        font-weight: 500;
    }
}

@media (min-width: 1024px){
    .estiloDivPrincipal {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 350vh;
        position: relative;
    }
    .estiloDivSticky {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        top: 0;
        opacity: 1;
        z-index: 2;
        background-color: black;
    }
    .estiloDivEstatico {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        position: absolute;
        bottom: 0;
        opacity: 1;
        z-index: 1
    }
    .tituloSticky {
        display: flex;
        font-size: 1.75em;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
        color: white;
        margin: 0px;
    }
    .cuadroScroll1 {
        position: absolute;
        top: 100vh;
        left: 15vw;
        width: 40vw;
        height: 45vh;
        background-color: #01ccc8;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll2 {
        position: absolute;
        top: 150vh;
        left: 45vw;
        width: 40vw;
        height: 45vh;
        background-color: #03787f;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll3 {
        position: absolute;
        top: 200vh;
        left: 15vw;
        width: 40vw;
        height: 45vh;
        background-color: #273782;
        z-index: 3;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .frasePrincipalDescripcionScroll {
    
        position: relative;
        transition: top 0.5s ease-in-out;
        overflow: hidden;
        height: 100%;
        width: 75%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    
    }
    .frasePrincipalScroll {
        font-size: 2.75vw;
        font-weight: 500;
        margin-bottom: 7.5px
    }
    .descripcionScroll {
        font-size: 2.25vw
    }
    .fraseSecundariaDescripcionScroll {
        position: absolute;
        color: white;
        top: 100%;
        /* Ajusta el valor según tu diseño original */
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
    
        height: 100%;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    }
    .fraseSecundariaScroll{
        font-size: 2.25vw
    }
    .expandir {
        position: absolute;
        font-size: 2.25vw;
        color: white;
        top: 140%;
        /* Ajusta el valor según tu diseño original */
        right: 15%;
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
        cursor: pointer;
        font-weight: 500;
    }
}

@media (min-width: 1280px){
    .estiloDivPrincipal {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 350vh;
        position: relative;
    }
    .estiloDivSticky {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        top: 0;
        opacity: 1;
        z-index: 2;
        background-color: black;
    }
    .estiloDivEstatico {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        position: absolute;
        bottom: 0;
        opacity: 1;
        z-index: 1
    }
    .tituloSticky {
        display: flex;
        font-size: 1.75em;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
        color: white;
        margin: 0px;
    }
    .cuadroScroll1 {
        position: absolute;
        top: 100vh;
        left: 15vw;
        width: 40vw;
        height: 45vh;
        background-color: #01ccc8;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll2 {
        position: absolute;
        top: 150vh;
        left: 45vw;
        width: 40vw;
        height: 45vh;
        background-color: #03787f;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll3 {
        position: absolute;
        top: 200vh;
        left: 15vw;
        width: 40vw;
        height: 45vh;
        background-color: #273782;
        z-index: 3;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .frasePrincipalDescripcionScroll {
    
        position: relative;
        transition: top 0.5s ease-in-out;
        overflow: hidden;
        height: 100%;
        width: 75%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    
    }
    .frasePrincipalScroll {
        font-size: 2.25vw;
        font-weight: 500;
        margin-bottom: 7.5px
    }
    .descripcionScroll {
        font-size: 1.75vw
    }
    .fraseSecundariaDescripcionScroll {
        position: absolute;
        color: white;
        top: 100%;
        /* Ajusta el valor según tu diseño original */
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
    
        height: 100%;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    }
    .fraseSecundariaScroll{
        font-size: 1.75vw
    }
    .expandir {
        position: absolute;
        font-size: 1.75vw;
        color: white;
        top: 140%;
        /* Ajusta el valor según tu diseño original */
        right: 15%;
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
        cursor: pointer;
        font-weight: 500;
    }
}

@media (min-width: 1560px){
    .estiloDivPrincipal {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 350vh;
        position: relative;
    }
    .estiloDivSticky {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        top: 0;
        opacity: 1;
        z-index: 2;
        background-color: black;
    }
    .estiloDivEstatico {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        position: absolute; 
        bottom: 0;
        opacity: 1;
        z-index: 1
    }
    .tituloSticky {
        display: flex;
        font-size: 1.75vw;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
        color: white;
        margin: 0px;
    }
    .cuadroScroll1 {
        position: absolute;
        top: 100vh;
        left: 25vw;
        width: 30vw;
        height: 45vh;
        background-color: #01ccc8;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll2 {
        position: absolute;
        top: 150vh;
        left: 45vw;
        width: 30vw;
        height: 45vh;
        background-color: #03787f;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll3 {
        position: absolute;
        top: 200vh;
        left: 25vw;
        width: 30vw;
        height: 45vh;
        background-color: #273782;
        z-index: 3;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .frasePrincipalDescripcionScroll {
    
        position: relative;
        transition: top 0.5s ease-in-out;
        overflow: hidden;
        height: 100%;
        width: 75%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    
    }
    .frasePrincipalScroll {
        font-size: 1.5vw;
        font-weight: 500;
        margin-bottom: 7.5px
    }
    .descripcionScroll {
        font-size: 1.25vw
    }
    .fraseSecundariaDescripcionScroll {
        position: absolute;
        color: white;
        top: 100%;
        /* Ajusta el valor según tu diseño original */
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
    
        height: 100%;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    }
    .fraseSecundariaScroll{
        font-size: 1.5vw
    }
    .expandir {
        position: absolute;
        font-size: 1vw;
        color: white;
        top: 140%;
        /* Ajusta el valor según tu diseño original */
        right: 15%;
        transition: top 0.5s ease-in-out;
        /* Modificado para animar el cambio en top */
        overflow: hidden;
        cursor: pointer;
        font-weight: 500;
    }
}



/*
@media only screen and (min-width: 2500px) {
    .tituloSticky {
        font-size: 1.25vw;
    }
    .estiloDivPrincipal {
        height: 250vh;
    }
    .cuadroScroll1 {
        top: 50vw;
        left: 42.5vw;
        width: 15vw;
        height: 10vw;
    }
    .cuadroScroll2 {
        top: 65vw;
        left: 42.5vw;
        width: 15vw;
        height: 10vw;
    }
    .cuadroScroll3 {
        top: 80vw;
        left: 42.5vw;
        width: 15vw;
        height: 10vw;
    }
    .frasePrincipalScroll {
        font-size: 1.25vw;
        margin-bottom: 15px
    }
    .descripcionScroll {
        font-size: 0.75vw
    }
    .fraseSecundariaDescripcionScroll {
        font-size: 0.75vw;
    }
    .fraseSecundariaScroll{
        font-size: 0.65vw
    }
    
    .expandir {
        font-size: 0.75vw;
    }
}

@media only screen and 
(min-width: 600px) and
(max-width: 2499px) and
(min-height: 600px) and
(max-height: 2499px){
    .estiloDivPrincipal {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 400vh;
        position: relative;
    }
    
    .estiloDivSticky {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        top: 0;
        opacity: 1;
        z-index: 2;
        background-color: black;
    }
    
    .estiloDivEstatico {
        background-color: black;
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        position: absolute;
        bottom: 0;
        opacity: 1;
        z-index: 1
    }
    
    
    
    .cuadroScroll1 {
    
        position: absolute;
        top: 50vw;
        left: 15vw;
        width: 30vw;
        height: 20vw;
        background-color: #01ccc8;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .cuadroScroll2 {
    
        position: absolute;
        top: 75vw;
        left: 50vw;
        width: 30vw;
        height: 20vw;
        background-color: #03787f;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .cuadroScroll3 {
        position: absolute;
        top: 100vw;
        left: 25vw;
        width: 30vw;
        height: 20vw;
        background-color: #273782;
        z-index: 3;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    
    
    .frasePrincipalDescripcionScroll {
    
        position: relative;
        transition: top 0.5s ease-in-out;
        overflow: hidden;
        height: 100%;
        width: 75%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    
    }
    
    .frasePrincipalScroll {
        font-size: 2.5vw;
        margin-bottom: 15px
    }
    
    .descripcionScroll {
        font-size: 1.30vw
    }
    
    .fraseSecundariaDescripcionScroll {
        position: absolute;
        color: white;
        font-size: 1.5vw;
        top: 100%;
        
        transition: top 0.5s ease-in-out;
      
        overflow: hidden;
    
        height: 100%;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    }
    .fraseSecundariaScroll{
        font-size: 1.30vw
    }
    
    .expandir {
        position: absolute;
        color: white;
        font-size: 1.5vw;
        top: 140%;
      
        right: 15%;
        transition: top 0.5s ease-in-out;
        
        overflow: hidden;
        cursor: pointer;
    }
    
    .tituloSticky {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
        color: white;
        font-size: 2.25vw;
        margin: 0px;
    }
}

@media only screen and 
(min-width: 760px) and
(max-width: 1370px) and
(min-height: 760px) and
(max-height: 1370px){
    .estiloDivPrincipal {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 350vh;
        position: relative;
    }
    
    .estiloDivSticky {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        top: 0;
        opacity: 1;
        z-index: 2;
        background-color: blue;
    }
    
    .estiloDivEstatico {
        background-color: black;
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        position: absolute;
        bottom: 0;
        opacity: 1;
        z-index: 1
    }
    
    
    
    .cuadroScroll1 {
    
        position: absolute;
        top: 100vh;
        left: 20vw;
        width: 45vw;
        height: 40vh;
        background-color: #01ccc8;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .cuadroScroll2 {
    
        position: absolute;
        top: 145vh;
        left: 30vw;
        width: 45vw;
        height: 40vh;
        background-color: #03787f;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .cuadroScroll3 {
        position: absolute;
        top: 190vh;
        left: 20vw;
        width: 45vw;
        height: 40vh;
        background-color: #273782;
        z-index: 3;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    
    .frasePrincipalDescripcionScroll {
    
        position: relative;
        transition: top 0.5s ease-in-out;
        overflow: hidden;
        height: 100%;
        width: 75%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    
    }
    
    .frasePrincipalScroll {
        font-size: 3.5vw;
        margin-bottom: 15px
    }
    
    .descripcionScroll {
        font-size: 2vw
    }
    
    .fraseSecundariaDescripcionScroll {
        position: absolute;
        color: white;
        font-size: 1.5vw;
        top: 100%;
        
        transition: top 0.5s ease-in-out;
    
        overflow: hidden;
    
        height: 100%;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    }
    .fraseSecundariaScroll{
        font-size: 1.75vw
    }
    
    .expandir {
        position: absolute;
        color: white;
        font-size: 2vw;
        top: 140%;
        
        right: 15%;
        transition: top 0.5s ease-in-out;
        
        overflow: hidden;
        cursor: pointer;
    }
    
}

@media only screen and 
(min-width: 540px) and 
(max-width: 1200px) and 
(min-height: 935px) and 
(max-height: 1200px){
    .estiloDivPrincipal {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 300vh;
        position: relative;
    }
    
    .estiloDivSticky {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        top: 0;
        opacity: 1;
        z-index: 2;
        background-color: red;
    }
    
    .estiloDivEstatico {
        background-color: black;
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        position: absolute;
        bottom: 0;
        opacity: 1;
        z-index: 1
    }
    
    
    
    .cuadroScroll1 {
    
        position: absolute;
        top: 100vh;
        left: 15vw;
        width: 50vw;
        height: 30vh;
        background-color: #01ccc8;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .cuadroScroll2 {
    
        position: absolute;
        top: 135vh;
        left: 35vw;
        width: 50vw;
        height: 30vh;
        background-color: #03787f;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .cuadroScroll3 {
        position: absolute;
        top: 170vh;
        left: 20vw;
        width: 50vw;
        height: 30vh;
        background-color: #273782;
        z-index: 3;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    
    
    .frasePrincipalDescripcionScroll {
    
        position: relative;
        transition: top 0.5s ease-in-out;
        overflow: hidden;
        height: 100%;
        width: 75%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    
    }
    
    .frasePrincipalScroll {
        font-size: 4vw;
        margin-bottom: 15px
    }
    
    .descripcionScroll {
        font-size: 2.5vw
    }
    
    .fraseSecundariaDescripcionScroll {
        position: absolute;
        color: white;
        font-size: 2.5vw;
        top: 100%;
        
        transition: top 0.5s ease-in-out;
     
        overflow: hidden;
    
        height: 100%;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    }
    .fraseSecundariaScroll{
        font-size: 2.5vw
    }
    
    .expandir {
        position: absolute;
        color: white;
        font-size: 2.5vw;
        top: 140%;
        
        right: 15%;
        transition: top 0.5s ease-in-out;
       
        overflow: hidden;
        cursor: pointer;
    }
    
    .tituloSticky {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
        color: white;
        font-size: 4vw;
        margin: 0px;
    }
}

@media only screen and 
(min-width: 50px) and
(max-width: 540px) and 
(min-height: 300px) and
(max-height: 935px){
    .estiloDivPrincipal {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 350vh;
        position: relative;
    }
    .estiloDivSticky {
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        top: 0;
        opacity: 1;
        z-index: 2;
        background-color: black;
    }
    .estiloDivEstatico {
        background-color: black;
        width: 100%;
        max-width: 2000px;
        height: 100vh;
        position: absolute;
        bottom: 0;
        opacity: 1;
        z-index: 1
    }

    .tituloSticky {
        display: flex;
        font-size: 3.5vw;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
        color: white;
        margin: 0px;
    }

    .cuadroScroll1 {
        position: absolute;
        top: 100vh;
        left: 5vw;
        width: 80vw;
        height: 45vh;
        background-color: #01ccc8;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll2 {
        position: absolute;
        top: 150vh;
        left: 15vw;
        width: 80vw;
        height: 45vh;
        background-color: #03787f;
        z-index: 3;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cuadroScroll3 {
        position: absolute;
        top: 200vh;
        left: 5vw;
        width: 80vw;
        height: 45vh;
        background-color: #273782;
        z-index: 3;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .frasePrincipalDescripcionScroll {
    
        position: relative;
        transition: top 0.5s ease-in-out;
        overflow: hidden;
        height: 100%;
        width: 75%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    
    }
    .frasePrincipalScroll {
        font-size: 2.5vh;
        margin-bottom: 7.5px
    }

    .descripcionScroll {
        font-size: 2vh
    }

    .fraseSecundariaDescripcionScroll {
        position: absolute;
        color: white;
        font-size: 2vh;
        top: 100%;
       
        transition: top 0.5s ease-in-out;
        
        overflow: hidden;
    
        height: 100%;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: white;
    }
    .fraseSecundariaScroll{
        font-size: 2vh
    }
    
    .expandir {
        position: absolute;
        font-size: 2vw;
        color: white;
        top: 140%;
       
        right: 15%;
        transition: top 0.5s ease-in-out;
       
        overflow: hidden;
        cursor: pointer;
    }
}
*/

